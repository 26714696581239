import React, { Fragment, useState, useEffect } from "react";
import "./seller.css";
import img from "../../imgs/Image 153.png";
import { connect } from "react-redux";
import { getProductCategories } from "../../actions/category";
//import { getProductDetails } from "../../actions/profile";
import auth from "../../reducers/auth";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";
import { updateProduct, getProductDetails } from "../../actions/profile";
import { setAlert } from "../../actions/alert";
import setAuthToken from "../../utils/setAuthToken";
import { useHistory, useParams } from "react-router-dom";
import Alert from "../layout/Alert";
import SellerSideBar from "./SellerSideBar";
import SellerHeader from "./SellerHeader";
const EditProduct = ({
  setAlert,
  updateProduct,
  getProductCategories,
  getProductDetails,
  categories: { categories },
  profile: { profile, loading, product },
}) => {
  const { id } = useParams();

  const categoriesList = Object.keys(categories).map((category) => (
    <option key={categories[category].id} value={categories[category].id}>
      {categories[category].name}
    </option>
  ));
  const [formData, setFormData] = useState({
    product_id: id,
    category_id: "",
    title: "",
    description: "",
    image: "",
    stock: "",
    price: "",
    sale: "",
  });

  useEffect(() => {
    if (product == null) {
      getProductDetails(id);
    }

    getProductCategories();

    if (product != null) {
      setFormData({
        product_id: id,
        category_id: !product.category_id ? "" : product.category_id,
        title: !product.title ? "" : product.title,
        description: !product.description ? "" : product.description,
        image: "",
        stock: !product.stock ? "" : product.stock,
        price: !product.price ? "" : product.price,
        sale: !product.sale ? "" : product.sale,
      });
    }
  }, [product]);

  const {
    product_id,
    category_id,
    title,
    description,
    image,
    stock,
    price,
    sale,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onFileChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });

  const onSubmit = async (e) => {
    e.preventDefault();
    updateProduct({
      product_id,
      category_id,
      title,
      description,
      image,
      stock,
      price,
      sale,
    });
  };
  return loading === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <SellerHeader />
      <div className="row ">
        <SellerSideBar />
        <div className="col-10">
          <div className="row bc-b9 pb-3 pt-3">
            <div className="col-10 bb-f3">
              <h3>Edit Product</h3>
            </div>

            <form
              method="post"
              onSubmit={(e) => onSubmit(e)}
              encType="multipart/form-data"
            >
              <div className="container">
                <Alert />
                <div className="form-group">
                  <label htmlFor="usr">Title:</label>
                  <input
                    required
                    type="text"
                    name="title"
                    value={title}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    id="usr"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="pwd">description:</label>
                  <input
                    required
                    type="text"
                    name="description"
                    value={description}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    id="pwd"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="sel1">Category:</label>
                  <select
                    name="category_id"
                    value={category_id}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    id="sel1"
                  >
                    <option value="0">select</option>
                    {categoriesList}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="pwd">stock:</label>
                  <input
                    required
                    type="number"
                    step=".01"
                    min="0"
                    name="stock"
                    value={stock}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    id="pwd"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="pwd">price:</label>
                  <input
                    required
                    type="number"
                    step=".01"
                    min="1"
                    name="price"
                    value={price}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    id="pwd"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="pwd">sale:</label>
                  <input
                    required
                    step=".01"
                    type="number"
                    min="0"
                    max="100"
                    name="sale"
                    value={sale}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    id="pwd"
                  />
                </div>
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    onChange={(e) => onFileChange(e)}
                    id="customFile"
                    name="image"
                  />
                  <label className="custom-file-label" htmlFor="customFile">
                    Choose Product Image
                  </label>
                </div>
                <br />
                <br />
                <div className="row justify-content-center">
                  <div className="col-10">
                    <input
                      type="submit"
                      className="btn btn-primary"
                      value="Update"
                      style={{
                        width: "40%",
                        marginLeft: "29%",
                        backgroundColor: "#5DADE2",
                      }}
                    ></input>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

EditProduct.prototypes = {
  setAlert: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  getProductCategories: PropTypes.func.isRequired,
  getProductDetails: PropTypes.func.isRequired,
  categories: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  categories: state.categories,
  profile: state.profile,
});

export default connect(mapStateToProps, {
  setAlert,
  updateProduct,
  getProductCategories,
  getProductDetails,
})(EditProduct);
