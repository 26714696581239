import React, { Fragment, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./buyerSide.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import HelpHeader from "./Help-Header";
import PropTypes from "prop-types";
import { getBuyerProductDetails } from "../../actions/profile";
import { createCart, addProductToCart } from "../../actions/cart";
import Alert from "../layout/Alert";

const SingleProductPage = ({
  getBuyerProductDetails,
  addProductToCart,
  createCart,
  profile: { profile, loading, product },
  cart: { cart },
}) => {
  const { id } = useParams();
  useEffect(() => {
    getBuyerProductDetails(id);
  }, []);
  const cartId = cart.id;
  const addProduct = (product_id, price) => {
    if (cartId != undefined) {
      addProductToCart(cartId, product_id, price);
    } else {
      createCart(product_id, price);
    }
  };
  const getPrice = (product) => {
    let p = 0;
    if (product.sale !== 0) {
      p = product.price * ((100 - product.sale) / 100);
      return p.toFixed(2);
    }
    return product.price;
  };
  return (
    <Fragment>
      <HelpHeader />
      <div className="super_container">
        {/* <header className="header" style={{ display: "none" }}>
          <div className="header_main">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-12 order-lg-2 order-3 text-lg-left text-right">
                  <div className="header_search">
                    <div className="header_search_content">
                      <div className="header_search_form_container">
                        <form
                          action="#"
                          className="header_search_form clearfix"
                        >
                          <div className="custom_dropdown">
                            <div className="custom_dropdown_list">
                              {" "}
                              <span className="custom_dropdown_placeholder clc">
                                All Categories
                              </span>{" "}
                              <i className="fas fa-chevron-down" />
                              <ul className="custom_list clc">
                                <li>
                                  <a className="clc" href="#">
                                    All Categories
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header> */}
        <div className="single_product">
          <Alert />
          <div
            className="container-fluid"
            style={{ backgroundColor: "#fff", padding: "11px" }}
          >
            <div className="row">
              <div className="col-lg-4 order-lg-2 order-1">
                <div className="image_selected">
                  <img src={product != null ? product.data.image : ""} alt="" />
                </div>
              </div>
              <div className="col-lg-6 order-3">
                <div className="product_description">
                  <div className="product_name">
                    <b>{product != null ? product.data.title : ""}</b>
                  </div>
                  {/* <div className="product-rating">
                    <span className="badge badge-success">
                      <i className="fa fa-star" /> 4.5 Star
                    </span>{" "}
                    <span className="rating-review">
                      35 Ratings &amp; 45 Reviews
                    </span>
                  </div> */}
                  <div>
                    {" "}
                    <span className="product_price">
                      $ {product != null ? getPrice(product.data) : ""}
                    </span>{" "}
                    {/* <strike className="product_discount">
                      {" "}
                      <span style={{ color: "black" }}>
                        $ 2,000<span> </span>
                      </span>
                    </strike>{" "} */}
                  </div>
                  {/* <div>
                    {" "}
                    <span className="product_saved">You Saved:</span>{" "}
                    <span style={{ color: "black" }}>
                      $ 2,000<span> </span>
                    </span>
                  </div> */}
                  <hr className="singleline" />
                  <div>
                    {" "}
                    <span className="product_info">
                      {product != null ? product.data.description : ""}
                      {/* <span>
                        <br />{" "}
                        <span className="product_info">
                          Warranty: 6 months warranty
                          <span>
                            <br />{" "}
                            <span className="product_info">
                              7 Days easy return policy
                              <span>
                                <br />{" "}
                                <span className="product_info">
                                  7 Days easy return policy
                                  <span>
                                    <br />{" "}
                                    <span className="product_info">
                                      In Stock
                                      <span> </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span> */}
                    </span>
                  </div>
                  {/* <div>
                    <div className="row">
                      <div className="col-md-5">
                        <div className="br-dashed">
                          <div className="row">
                            <div className="col-md-3 col-xs-3">
                              {" "}
                              <img src="https://img.icons8.com/color/48/000000/price-tag.png" />{" "}
                            </div>
                            <div className="col-md-9 col-xs-9">
                              <div className="pr-info">
                                {" "}
                                <span className="break-all">
                                  Get 5% instant discount + 10X rewards @
                                  RENTOPC
                                </span>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7"> </div>
                    </div>
                    <div className="row" style={{ marginTop: "15px" }}>
                      <div className="col-xs-6" style={{ marginLeft: "15px" }}>
                        {" "}
                        <span className="product_options">RAM Options</span>
                        <br />{" "}
                        <button className="btn btn-primary btn-sm">
                          4 GB
                        </button>{" "}
                        <button className="btn btn-primary btn-sm">8 GB</button>{" "}
                        <button className="btn btn-primary btn-sm">
                          16 GB
                        </button>{" "}
                      </div>
                      <div className="col-xs-6" style={{ marginLeft: "55px" }}>
                        {" "}
                        <span className="product_options">Storage Options</span>
                        <br />{" "}
                        <button className="btn btn-primary btn-sm">
                          500 GB
                        </button>{" "}
                        <button className="btn btn-primary btn-sm">1 TB</button>{" "}
                      </div>
                    </div>
                  </div> */}
                  <hr className="singleline" />
                  <div className="order_info d-flex flex-row">
                    <form action="#"></form>
                  </div>
                  <div className="row">
                    {/* <div className="col-xs-6" style={{ marginLeft: "13px" }}>
                      <div className="product_quantity">
                        {" "}
                        <span>QTY: </span>{" "}
                        <input
                          id="quantity_input"
                          type="text"
                          pattern="[0-9]*"
                          defaultValue={1}
                        />
                        <div className="quantity_buttons">
                          <div
                            id="quantity_inc_button"
                            className="quantity_inc quantity_control"
                          >
                            <i className="fas fa-chevron-up" />
                          </div>
                          <div
                            id="quantity_dec_button"
                            className="quantity_dec quantity_control"
                          >
                            <i className="fas fa-chevron-down" />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-xs-6">
                      {" "}
                      {product != null && product.data.stock == 0 ? (
                        <button
                          type="button"
                          className="btn btn-primary shop-button"
                        >
                          Out Of Stock
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() =>
                            addProduct(
                              product != null ? product.data.id : "",
                              product != null ? product.data.price : ""
                            )
                          }
                          className="btn btn-primary shop-button"
                        >
                          Add to Cart
                        </button>
                      )}
                      {/* <button
                        type="button"
                        className="btn btn-success shop-button"
                      >
                        Buy Now
                      </button> */}
                      <div className="product_fav">
                        <i className="fas fa-heart" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

SingleProductPage.prototypes = {
  getBuyerProductDetails: PropTypes.func.isRequired,
  createCart: PropTypes.func.isRequired,
  addProductToCart: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  cart: state.cart,
});

export default connect(mapStateToProps, {
  getBuyerProductDetails,
  createCart,
  addProductToCart,
})(SingleProductPage);
