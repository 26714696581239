import { Fragment } from "react";
import Slider from "./Slider";
import Body from "./Body";
import BackToTop from "./backToTop";
import Email from "./Email";

const Home = () => {
  return (
    <Fragment>
      <Slider />
      <Body />
      {/* <BackToTop /> */}
      {/* <Email /> */}
    </Fragment>
  );
};

export default Home;
