import React, { Fragment } from "react";
import "./cozillion.css";
import { Link } from "react-router-dom";
import imag122 from "../imgs/Image 122.png";
import imag123 from "../imgs/Image 123.png";
import imag124 from "../imgs/Image 124.png";
import imag125 from "../imgs/Image 125.png";
import imag35 from "../imgs/Component 35.png";
import imag121 from "../imgs/Image 121.png";

const About = () => {
  return (
    <Fragment>
      <div className="container-fluid mt-3 about-image">
        <div className="container pt-4">
          <div className="row justify-content-md-center">
            <div className="col-md-auto cl-w hel px42 mt-5"> Lorem Ipsum</div>
            <div className="col-md-auto cl-w hel px20 text-center mt-3 tl">
              {" "}
              Using color to add meaning only provides a visual indication,
              which will not be conveyed to users of assistive technologies –
              such as screen readers. Ensure that information denoted by the
              color is either obvious from the content itself (e.g. the visible
              text), or is included through alternative means, such as
              additional text hidden with the .visually-hidden class.
            </div>
          </div>
        </div>
        <div className="container-fluid pt-5" style={{ marginBottom: "150px" }}>
          <div className="row">
            <div className="col-6 col-sm-3">
              <div className="row justify-content-md-center">
                <div className="col-9 bd-w">
                  <img className="img-lorem" src={imag122} />
                  <div className="cl-w px20 text-center ">
                    Loreem ipsum dolor sit through alternative means
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-3">
              <div className="row justify-content-md-center">
                <div className="col-9 bd-w">
                  <img className="img-lorem" src={imag123} />
                  <div className="cl-w px20 text-center ">
                    Loreem ipsum dolor sit through alternative means
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-3 mmt-2">
              <div className="row justify-content-md-center">
                <div className="col-9 bd-w">
                  <img className="img-lorem pt-1" src={imag124} />
                  <div className="cl-w px20 text-center ">
                    Loreem ipsum dolor sit through alternative means
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-3 mmt-2">
              <div className="row justify-content-md-center">
                <div className="col-9 bd-w">
                  <img className="img-lorem" src={imag125} />
                  <div className="cl-w px20 text-center ">
                    Loreem ipsum dolor sit through alternative means
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-12 col-sm-5">
              <img src={imag121} style={{ width: "100%", height: "100%" }} />
            </div>
            <div className="col-12 col-sm-6 dml-5">
              <div className="px20 cl-88 hel">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa deserunt mollit anim id est laborum.
              </div>
              <button type="button" className="btn btn-lor">
                <Link
                  to="/contact"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  Contact Us
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bcd7">
        <div className="container">
          <div className="row pt-5 pb-5 justify-content-center">
            <div className="col-12 col-sm-6 text-center ">
              <div className="hel px28 fwb text-center">
                Are you a Buyer or Seller?
              </div>
              <div className="hel px24 text-center">
                Create an account today and get a <br />
                chance to browse our directory{" "}
              </div>

              <div style={{ marginTop: "20px" }}>
                <button
                  type="button"
                  className="btn bc03 cl-w px20 hel"
                  style={{ width: "40%" }}
                >
                  <Link
                    to="/"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Get Started
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default About;
