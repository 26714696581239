import React from "react";
import SellerLogin from "./SellerLogin";

const SellerLoginPage = () => {
  return (
    <>
      <SellerLogin />
    </>
  );
};

export default SellerLoginPage;
