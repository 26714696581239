import React from "react";
import "./buyerSide.css";
import img from "../../imgs/Group 478.png";
import HelpHeader from "./Help-Header";

const HelpCentre = () => {
  return (
    <>
      <HelpHeader />
      <div className="helpcentre ">
        <div className="help-text">Help Centre</div>
        <div className="help-img bc-img">
          {/* <img src={img}  /> */}
          <div className="help-search">
            <input
              className="form-control form-control-sm"
              type="text"
              style={{
                width: "70%",
                marginLeft: "10%",
                marginTop: "35px",
                borderRadius: "15px",
              }}
            ></input>
            <div className="search-btn">Search</div>
          </div>
        </div>
        <div style={{ textAlign: "center", fontSize: "3em", color: "#16394c" }}>
          Topics
        </div>
        <div className="topic">
          <div
            className="to-he"
            style={{ backgroundColor: "#0f3346", color: "white" }}
          >
            My Account
          </div>
          <div
            className="to-he"
            style={{ backgroundColor: "#e5f6ff", color: "#0f3346" }}
          >
            Purchases
          </div>
          <div
            className="to-he"
            style={{ backgroundColor: "#0f3346", color: "white" }}
          >
            Wallet
          </div>
          <div
            className="to-he"
            style={{ backgroundColor: "#e5f6ff", color: "#0f3346" }}
          >
            Delivery
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpCentre;
