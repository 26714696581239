import axios from "axios";
import { setAlert } from "./alert";

import {
  GET_CATEGORY,
  CATEGORY_ERROR,
  CREATE_CART_SUCCESS,
  CREATE_CART_FAIL,
  GET_CART_ITEMS,
  CART_ERROR,
  EMPTY_CART,
  GET_SHOP_ORDERS,
  GET_USER_ORDERS,
} from "./types";

import { loadStripe } from "@stripe/stripe-js";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_test_51IgyiKIpZ03ilQhvcUtGFHV1bQIdlHKq31GlecLSNrT79i5w0oJWqiwTiqipQ1q0Bgz3biXJVANYLYDdZGpxMVr300p1CfXqYN"
);

export const createCart = (product_id, price) => async (dispatch) => {
  const config = {
    headers: {},
  };

  var bodyFormData = new FormData();

  try {
    const res = await axios.post(
      "https://cozillion.com/api/api/auth/cart",
      bodyFormData,
      config
    );

    dispatch({
      type: CREATE_CART_SUCCESS,
      payload: res.data.data,
    });
    dispatch(addProductToCart(res.data.data.id, product_id, price));
    //dispatch(setAlert("Inserted Successfully", "success"));
  } catch (err) {
    for (var key in err.response.data.errors) {
      var obj = err.response.data.errors[key];
      dispatch(setAlert(obj[0], "danger"));
    }
    dispatch({
      type: CREATE_CART_FAIL,
    });
  }
};

export const addProductToCart = (id, product_id, price) => async (dispatch) => {
  const config = {
    headers: {},
  };

  var bodyFormData = new FormData();
  bodyFormData.append("cart_id", id);
  bodyFormData.append("product_id", product_id);
  bodyFormData.append("price", price);
  bodyFormData.append("quantity", 1);

  try {
    const res = await axios.post(
      "https://cozillion.com/api/api/auth/cart_item",
      bodyFormData,
      config
    );

    // dispatch({
    //   type: CREATE_CART_SUCCESS,
    //   payload: res.data.data,
    // });
    dispatch(setAlert("Added Successfully", "success"));
  } catch (err) {
    for (var key in err.response.data.errors) {
      var obj = err.response.data.errors[key];
      dispatch(setAlert(obj[0], "danger"));
    }
    dispatch({
      type: CREATE_CART_FAIL,
    });
  }
};

export const getProductCategories = () => async (dispatch) => {
  try {
    const res = await axios.get(
      "https://cozillion.com/api/api/auth/product_categories"
    );

    dispatch({
      type: GET_CATEGORY,
      payload: res.data.categories,
    });
  } catch (err) {
    dispatch({
      type: CATEGORY_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.data,
      },
    });
  }
};

export const getCartItems = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      "https://cozillion.com/api/api/auth/cart/" + id
    );
    dispatch({
      type: GET_CART_ITEMS,
      payload: res.data.data[0].cart_items,
    });
    dispatch({
      type: CREATE_CART_SUCCESS,
      payload: res.data.data[0],
    });
  } catch (err) {
    dispatch({
      type: CART_ERROR,
      payload: {
        msg: "Failed to get cart items",
        status: "442",
      },
    });
  }
};

export const updateProductItem = (id, price, quantity) => async (dispatch) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var bodyFormData = new FormData();
  bodyFormData.append("price", price);
  bodyFormData.append("quantity", quantity);

  bodyFormData.append("_method", "PUT");

  try {
    const res = await axios.post(
      "https://cozillion.com/api/api/auth/cart_item/" + id,
      bodyFormData,
      config
    );

    dispatch({
      type: CREATE_CART_SUCCESS,
      payload: res.data.data[0],
    });

    dispatch(setAlert("Update Successful", "success"));
  } catch (err) {
    // if (err.response.status == "401") {
    //   dispatch(setAlert(err.response.data.message, "danger"));
    // } else {
    //   for (var key in err.response.data.errors) {
    //     var obj = err.response.data.errors[key];
    //     dispatch(setAlert(obj[0], "danger"));
    //   }
    // }
    // dispatch({
    //   type: CREATE_PRODUCT_FAIL,
    // });
  }
};

// ORDERS AREA

export const addOrder =
  (cart_id, status = "PROCESSING_STOCK") =>
  async (dispatch) => {
    const config = {
      headers: {},
    };

    var bodyFormData = new FormData();
    bodyFormData.append("cart_id", cart_id);
    bodyFormData.append("status", status);

    // try {
    const res = await axios.post(
      "https://cozillion.com/api/api/auth/order",
      bodyFormData,
      config
    );

    // Get Stripe.js instance
    const stripe = await stripePromise;
    console.log(res.data.sessionId);
    const result = await stripe.redirectToCheckout({
      sessionId: res.data.sessionId,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }

    dispatch({
      type: EMPTY_CART,
    });
    dispatch(setAlert("Order Placed Successfully", "success"));
    // } catch (err) {
    //   // for (var key in err.response.data.errors) {
    //   //   var obj = err.response.data.errors[key];
    //   //   dispatch(err, "danger");
    //   // }
    //   dispatch(setAlert(err, "danger"));
    //   dispatch({
    //     type: CREATE_CART_FAIL,
    //   });
    // }
  };

export const getShopOrders =
  (q = "") =>
  async (dispatch) => {
    try {
      const res = await axios.get(
        "https://cozillion.com/api/api/auth/shop_orders?q=" + q
      );
      dispatch({
        type: GET_SHOP_ORDERS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: CART_ERROR,
        payload: {
          msg: "Failed to get cart items",
          status: "442",
        },
      });
    }
  };

export const updateItemStatus = (id, status) => async (dispatch) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var bodyFormData = new FormData();
  bodyFormData.append("status", status);

  try {
    const res = await axios.post(
      "https://cozillion.com/api/api/auth/cart_item/update_item_status/" + id,
      bodyFormData,
      config
    );

    dispatch(setAlert("Update Successful", "success"));
  } catch (err) {
    console.log(err);
  }
};

export const getUserOrders =
  (q = "") =>
  async (dispatch) => {
    try {
      const res = await axios.get(
        "https://cozillion.com/api/api/auth/order?q=" + q
      );
      dispatch({
        type: GET_USER_ORDERS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: CART_ERROR,
        payload: {
          msg: "Failed to get cart items",
          status: "442",
        },
      });
    }
  };
