import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  NavLink,
  Switch,
  Route,
} from "react-router-dom";
import Home from "../Home";
import BuyerLoginPage from "../buyer/BuyerLogin";
import ForgotPassword from "../buyer/ForgotPassword";
import AboutUs from "../AboutUs";
import Contact from "../Contact";
import Buyer from "../buyer/Buyer";
import Seller from "../seller/Seller";
import HelpHeader from "../BuyerSidePage/Help-Header";
import HelpCentre from "../BuyerSidePage/HelpCentre";
import HomePageBuyer from "../BuyerSidePage/HomePageBuyer";
import MyAccountBuyer from "../BuyerSidePage/MyAccountBuyer";
import OrderPage from "../BuyerSidePage/OrderPage";
import NewInPage from "../BuyerSidePage/NewInPage";
import ProductsPage from "../BuyerSidePage/ProductsPage";
import ShoppingBasket from "../BuyerSidePage/ShoppingBasket";
import ViewSuppliersPage from "../BuyerSidePage/ViewSuppliersPage";
import WalletPage from "../BuyerSidePage/WalletPage";
import SellerSideBar from "../SellerSidePages/SellerSideBar";
import SellerHeader from "../SellerSidePages/SellerHeader";
import SHelpCentre from "../SellerSidePages/SHelpCentre";
import AccountPage from "../SellerSidePages/AccountPage";
import SupplierTransaction from "../SellerSidePages/SupplierTransaction";
import SupplierHome from "../SellerSidePages/SupplierHome";
import SupplierProducts from "../SellerSidePages/SupplierProducts";
import PrivateRoute from "./PrivateRoute";
import BuyerSignupPage from "../buyer/BuyerSignupPage";
import SellerSignupPage from "../seller/SellerSignupPage";
import SellerLoginPage from "../seller/SellerLoginPage";
import Footer from "../Footer";
import Header from "../Header";
import AddCard from "../BuyerSidePage/AddCard";
import EditCard from "../BuyerSidePage/EditCard";
import EditAddress from "../BuyerSidePage/EditAddress";
import AddressList from "../BuyerSidePage/AddressList";
import AddAddress from "../BuyerSidePage/AddAddress";
import SingleProductPage from "../BuyerSidePage/SingleProductPage";

import EditProduct from "../SellerSidePages/EditProduct";
import AddProduct from "../SellerSidePages/AddProduct";

const Routes = () => {
  return (
    <Fragment>
      <Header />
      <Switch>
        {/* <Route exact path="/" component={HomePageBuyer} /> */}
        <Route exact path="/" component={Home} />
        <PrivateRoute exact path="/Buy" component={HomePageBuyer} />
        <PrivateRoute exact path="/Buy/NewIn" component={NewInPage} />
        <PrivateRoute exact path="/Buy/Products" component={ProductsPage} />
        <PrivateRoute exact path="/Buy/Wallet" component={WalletPage} />
        <PrivateRoute exact path="/Buy/Wallet/Add" component={AddCard} />
        <PrivateRoute exact path="/Buy/Address/Add" component={AddAddress} />
        <PrivateRoute exact path="/Buy/Card/Edit/:id" component={EditCard} />
        <PrivateRoute exact path="/ShoppingBasket" component={ShoppingBasket} />
        <PrivateRoute exact path="/Buy/Orders" component={OrderPage} />
        <PrivateRoute
          exact
          path="/Buy/Product/:id"
          component={SingleProductPage}
        />
        <PrivateRoute
          exact
          path="/Buy/Address/Edit/:id"
          component={EditAddress}
        />
        <PrivateRoute exact path="/Buy/Address" component={AddressList} />
        <PrivateRoute
          exact
          path="/Buy/Supplier"
          component={ViewSuppliersPage}
        />
        <PrivateRoute exact path="/Buy/Help" component={HelpCentre} />
        {/* <PrivateRoute exact path="/Sel" component={SellerSideBar} /> */}
        <PrivateRoute exact path="/Sel" component={SupplierHome} />
        <PrivateRoute exact path="/Sel/trns" component={SupplierTransaction} />
        <PrivateRoute exact path="/Sel/pro" component={SupplierProducts} />
        <PrivateRoute exact path="/Sel/add_product" component={AddProduct} />
        <PrivateRoute exact path="/Sel/help" component={SHelpCentre} />
        <PrivateRoute
          exact
          path="/Sel/product/edit/:id"
          component={EditProduct}
        />
        <Route exact path="/Buy/Products" component={ProductsPage} />
        <Route exact path="/buyer/login" component={BuyerLoginPage} />
        <Route exact path="/forgot_password" component={ForgotPassword} />
        <Route exact path="/buyer/signup" component={BuyerSignupPage} />
        <Route exact path="/seller/login" component={SellerLoginPage} />
        <Route exact path="/seller/signup" component={SellerSignupPage} />
        <Route exact path="/about" component={AboutUs} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/te" component={AccountPage} />
        <Route exact path="/buyer" component={Buyer} />
        <Route exact path="/buyer/home" component={HomePageBuyer} />
        <PrivateRoute exact path="/buyer/profile" component={MyAccountBuyer} />
        <PrivateRoute exact path="/seller/profile" component={MyAccountBuyer} />
        <Route exact path="/seller" component={Seller} />
        <Route exact path="/seller/signup" component={SellerSignupPage} />
      </Switch>
      <Footer />
    </Fragment>
  );
};

export default Routes;
