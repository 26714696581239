import React from "react";
import "../cozillion.css";
import { Link, Redirect } from "react-router-dom";
import imgb from "../../imgs/Image 82.png";
import imgblu from "../../imgs/Rectangle 55.png";
import imgbla from "../../imgs/Rectangle 56.png";
import imgred from "../../imgs/Rectangle 57.png";

const HelloSeller = () => {
  function login() {
    // var newURL = window.location.pathname + "/login";
    // window.location.replace(newURL);
  }
  function signup() {
    // var newURL = window.location.pathname + "/signup";
    // window.location.replace(newURL);
  }

  return (
    <>
      <div
        className="container-fluid mt-4 pt-5 pb-5"
        style={{ backgroundColor: "#f8f9f9" }}
      >
        <div className="row">
          <div className="col-12 col-sm-6">
            <div className="row justify-content-center">
              <div className="col-10">
                <div className="mtac">
                  <div className="buy-text">Hello Seller</div>
                  <div className="img-line">
                    <img src={imgblu} className="line-width" />
                  </div>
                  <div className="img-line">
                    <img src={imgbla} className="line-width" />
                  </div>
                  <div className="img-line">
                    <img src={imgred} className="line-width" />
                  </div>
                </div>
                <div className="buy-text2 desktop">
                  Welcome to Cozillion, we have a lot in store for you. <br />
                  Please select your access options below
                </div>
                <div className="buy-text2 mobile text-center">
                  Welcome to Cozillion, we have a lot in store for you. Please
                  select your access options below
                </div>
                <div className="container pt-4">
                  <div className="row">
                    <div className="col-4 mml-4">
                      <button
                        type="button"
                        className="btn btn-login btn-siz"
                        onClick={signup}
                      >
                        <Link
                          to="/seller/signup"
                          style={{
                            color: "inherit",
                            textDecoration: "inherit",
                          }}
                        >
                          Sign Up
                        </Link>
                      </button>
                    </div>
                    <div className="col-4">
                      <button
                        type="button"
                        className="btn btn-login btn-siz"
                        onClick={login}
                      >
                        <Link
                          to="/seller/login"
                          style={{
                            color: "inherit",
                            textDecoration: "inherit",
                          }}
                        >
                          Login
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 mmt-2">
            <img src={imgb} style={{ width: "100%" }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default HelloSeller;
