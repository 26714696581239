import React, { Fragment, useState, useEffect } from "react";
import "./buyerSide.css";
import img7 from "../../imgs/product7.png";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { getProductCategories } from "../../actions/category";
import { getAllProducts, queryProduct } from "../../actions/profile";
import { createCart, addProductToCart } from "../../actions/cart";
import { setAlert } from "../../actions/alert";
import HelpHeader from "./Help-Header";
import Alert from "../layout/Alert";

const ProductsPage = ({
  setAlert,
  createCart,
  getProductCategories,
  getAllProducts,
  queryProduct,
  addProductToCart,
  categories: { categories },
  profile: { products, query },
  cart: { cart },
}) => {
  const [q, setQ] = useState("");
  const [cat, setCat] = useState([]);

  useEffect(() => {
    getProductCategories();
    if (q == "" && cat == []) {
      if (query !== "") {
        //getAllProducts(query, cat);
        setQ(query);
        //queryProduct("");
      } else {
        getAllProducts();
      }
    } else {
      if (query !== "") {
        //getAllProducts(query, cat);
        setQ(query);
        //queryProduct("");
      } else {
        getAllProducts(q, cat);
      }
    }
  }, [getProductCategories, cat, q, query]);

  const { id } = cart;
  const addProduct = (product_id, price) => {
    if (id != undefined) {
      addProductToCart(id, product_id, price);
    } else {
      createCart(product_id, price);
    }
  };
  const categoriesList = Object.keys(categories).map((category) => (
    <div key={categories[category].id}>
      <input
        className="form-check-input"
        type="checkbox"
        onChange={(e) => searchProductsByCat(categories[category].id)}
        id="defaultCheck1"
      ></input>
      <label className="form-check-label" htmlFor="defaultCheck1">
        {categories[category].name}
      </label>
    </div>
  ));

  const searchProductsByCat = (id) => {
    if (cat.includes(id)) {
      setCat(cat.filter((item) => item !== id));
    } else {
      setCat((oldArray) => [...oldArray, id]);
    }
  };

  const searchProducts = (e) => {
    queryProduct("");
    setQ(e.target.value);
  };
  const getPrice = (product) => {
    let p = 0;
    if (product.sale !== 0) {
      p = product.price * ((100 - product.sale) / 100);
      return p.toFixed(2);
    }
    return product.price;
  };

  const productsList = products.map((product) => (
    <div key={product.id} className="fp-it">
      <div className="fp-img">
        <NavLink
          to={"Product/" + product.id}
          exact
          className="link"
          activeclassname="active"
        >
          <img src={product.image} style={{ height: "100%", width: "100%" }} />
        </NavLink>
      </div>
      <div className="fp-name">{product.title}</div>
      <div className="fp-star">*****</div>
      <div className="fp-price">${getPrice(product)}</div>
      {product.stock <= 0 ? (
        <div style={{ cursor: "pointer" }} className="fp-buy">
          Out Of Stock
        </div>
      ) : (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => addProduct(product.id, product.price)}
          className="fp-buy"
        >
          BUY
        </div>
      )}
    </div>
  ));

  return (
    <>
      <HelpHeader />
      <div
        className="productpage"
        style={{
          backgroundColor: "#f8f8f8",
          height: "710px",
          display: "inline",
          paddingBottom: "50px",
        }}
      >
        <div className="myaccount">
          <h2
            style={{ marginLeft: "5%", color: "#274758", paddingTop: "20px" }}
          >
            Products
          </h2>
          <div
            className="my-acc"
            style={{
              width: "86%",
              borderBottom: "2px solid #0f3346",
              marginLeft: "5%",
            }}
          ></div>
        </div>
        <div className="products1">
          <div className="catselect">
            <div className="myaccount">
              <h4
                style={{
                  marginLeft: "5%",
                  color: "#274758",
                  paddingTop: "20px",
                }}
              >
                Categories
              </h4>
              <div
                className="my-acc"
                style={{
                  width: "86%",
                  borderBottom: "2px solid #0f3346",
                  marginLeft: "5%",
                }}
              ></div>
            </div>

            <div className="form-check" style={{ marginLeft: "3%" }}>
              {categoriesList}
            </div>
          </div>

          {/* <div className="recommend">
            <div className="myaccount">
              <h4
                style={{
                  marginLeft: "5%",
                  color: "#274758",
                  paddingTop: "20px",
                }}
              >
                Recommend
              </h4>
              <div
                className="my-acc"
                style={{
                  width: "86%",
                  borderBottom: "2px solid #0f3346",
                  marginLeft: "5%",
                }}
              ></div>
            </div>

            <div className="rec-1">
              <div className="rec-img">img</div>
              <div className="rec-title">Habitat Rufus Valvet</div>
              <div className="rec-star">*****</div>
              <div className="rec-price">$15.99</div>
              <div className="rec-open">Open</div>
            </div>
            <div className="rec-1">
              <div className="rec-img">img</div>
              <div className="rec-title">Habitat Rufus Valvet</div>
              <div className="rec-star">*****</div>
              <div className="rec-price">$15.99</div>
              <div className="rec-open">Open</div>
            </div>
            <div className="rec-1">
              <div className="rec-img">img</div>
              <div className="rec-title">Habitat Rufus Valvet</div>
              <div className="rec-star">*****</div>
              <div className="rec-price">$15.99</div>
              <div className="rec-open">Open</div>
            </div>
          </div> */}
        </div>

        <div className="products2">
          <div style={{ height: "7%", width: "85%", marginLeft: "5%" }}>
            <input
              type="search"
              value={q}
              placeholder="Filter"
              onChange={(e) => searchProducts(e)}
              style={{ width: "100%", height: "100%" }}
            ></input>
          </div>
          <div className="filterproducts">
            <Alert />
            {productsList}
          </div>
        </div>
      </div>
    </>
  );
};

ProductsPage.prototypes = {
  setAlert: PropTypes.func.isRequired,
  getProductCategories: PropTypes.func.isRequired,
  getAllProducts: PropTypes.func.isRequired,
  categories: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  createCart: PropTypes.func.isRequired,
  addProductToCart: PropTypes.func.isRequired,
  queryProduct: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  categories: state.categories,
  profile: state.profile,
  cart: state.cart,
});

export default connect(mapStateToProps, {
  setAlert,
  getProductCategories,
  getAllProducts,
  createCart,
  addProductToCart,
  queryProduct,
})(ProductsPage);
