import {
  GET_ADDRESSES,
  GET_ADDRESS,
  ADDRESSES_ERROR,
  UPDATE_ADDRESSES,
  UPDATE_ADDRESS,
} from "../actions/types";

const initialState = {
  addresses: [],
  address: null,
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ADDRESSES:
    case UPDATE_ADDRESSES:
      return {
        ...state,
        addresses: payload,
        loading: false,
        address: null,
      };

    case GET_ADDRESS:
    case UPDATE_ADDRESS:
      return {
        ...state,
        address: payload,
        loading: false,
      };

    case ADDRESSES_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
