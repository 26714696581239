import axios from "axios";
import { setAlert } from "./alert";

import { GET_CATEGORY, CATEGORY_ERROR } from "./types";

export const getProductCategories = () => async (dispatch) => {
  try {
    const res = await axios.get(
      "https://cozillion.com/api/api/auth/product_categories"
    );

    dispatch({
      type: GET_CATEGORY,
      payload: res.data.categories,
    });
  } catch (err) {
    dispatch({
      type: CATEGORY_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.data,
      },
    });
  }
};
