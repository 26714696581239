import React from "react";
import HelloSeller from "./HelloSeller";

const Seller = () => {
  return (
    <>
      <HelloSeller />
    </>
  );
};

export default Seller;
