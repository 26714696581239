import React, { Fragment, useState, useEffect } from "react";
import img from "../../imgs/Image 153.png";
import { connect } from "react-redux";
import auth from "../../reducers/auth";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";
import { updateAddress, getAddressDetails } from "../../actions/profile";
import { useHistory, useParams } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import setAuthToken from "../../utils/setAuthToken";
import Alert from "../layout/Alert";
import HelpHeader from "./Help-Header";

const EditAddress = ({
  setAlert,
  updateAddress,
  getAddressDetails,
  addresses: { addresses, loading, address },
}) => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    user_address: "",
  });

  useEffect(() => {
    if (address == null) {
      getAddressDetails(id);
    }

    if (address != null) {
      setFormData({
        address_id: id,
        user_address: address.address,
      });
    }
  }, [address]);

  const { address_id, user_address } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    updateAddress({
      address_id,
      user_address,
    });
  };
  return (
    <Fragment>
      <HelpHeader />
      <div className="row bc-b9 pb-3 pt-3">
        <form
          method="post"
          onSubmit={(e) => onSubmit(e)}
          encType="multipart/form-data"
        >
          <div className="container">
            <Alert />
            <div>
              <h3>Edit Address</h3>
            </div>
            <div className="form-group">
              <label htmlFor="usr">Bank Name:</label>
              <input
                type="text"
                name="user_address"
                value={user_address}
                onChange={(e) => onChange(e)}
                className="form-control"
                id="usr"
              />
            </div>

            <br />
            <br />
            <div className="row justify-content-center">
              <div className="col-10">
                <input
                  type="submit"
                  className="btn btn-primary"
                  value="Update"
                  style={{
                    width: "40%",
                    marginLeft: "29%",
                    backgroundColor: "#5DADE2",
                  }}
                ></input>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

EditAddress.prototypes = {
  setAlert: PropTypes.func.isRequired,
  updateAddress: PropTypes.func.isRequired,
  getAddressDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  categories: state.categories,
  addresses: state.addresses,
});

export default connect(mapStateToProps, {
  setAlert,
  updateAddress,
  getAddressDetails,
})(EditAddress);
