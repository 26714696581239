import React, { Fragment, useState } from "react";
import "./cozillion.css";
import { subscribeNewsletter } from "../actions/profile";
import Alert from "./layout/Alert";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Email = ({ subscribeNewsletter }) => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const { email } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    subscribeNewsletter(email);
  };
  return (
    <Fragment>
      <div className="container-fluid bcg email-container">
        <Alert />
        <form
          onSubmit={(e) => onSubmit(e)}
          className="form-inline"
          method="post"
        >
          <input
            required
            type="email"
            name="email"
            value={email}
            onChange={(e) => onChange(e)}
            className="form-control em-e"
            placeholder="Enter email"
            id="email"
          ></input>

          <button type="submit" className="btn btn-primary sb-h bck">
            SEND
          </button>
        </form>
      </div>
    </Fragment>
  );
};
Email.prototypes = {
  subscribeNewsletter: PropTypes.func.isRequired,
};

export default connect(null, { subscribeNewsletter })(Email);
