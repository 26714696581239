import BuyerSignUp from "./BuyerSignUp";
import Footer from "../Footer";

const BuyerSignupPage = () => {
  return (
    <>
      <BuyerSignUp />
    </>
  );
};

export default BuyerSignupPage;
