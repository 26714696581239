import React, { Fragment } from "react";
import About from "./About";
import Email from "./Email";

const AboutUs = () => {
  return (
    <Fragment>
      <About />
      {/* <Email /> */}
    </Fragment>
  );
};

export default AboutUs;
