import React, { Fragment, useState, useEffect } from "react";
import img from "../../imgs/Image 153.png";
import { connect } from "react-redux";
import auth from "../../reducers/auth";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";
import { updateCard, getCardDetails } from "../../actions/profile";
import { useHistory, useParams } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import setAuthToken from "../../utils/setAuthToken";
import Alert from "../layout/Alert";
import HelpHeader from "./Help-Header";

const EditCard = ({
  setAlert,
  updateCard,
  getCardDetails,
  cards: { cards, loading, card },
}) => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    bank_name: "",
    title: "",
    number: "",
    expiry_date: "",
    security_code: "",
  });

  useEffect(() => {
    if (card == null) {
      getCardDetails(id);
    }

    if (card != null) {
      setFormData({
        card_id: id,
        bank_name: card.bank_name,
        title: card.title,
        number: card.number,
        expiry_date: card.expiry_date,
        security_code: card.security_code,
      });
    }
  }, [card]);

  const {
    card_id,
    bank_name,
    title,
    number,
    expiry_date,
    security_code,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    updateCard({
      card_id,
      bank_name,
      title,
      number,
      expiry_date,
      security_code,
    });
  };
  return (
    <Fragment>
      <HelpHeader />
      <div className="row bc-b9 pb-3 pt-3">
        <form
          method="post"
          onSubmit={(e) => onSubmit(e)}
          encType="multipart/form-data"
        >
          <div className="container">
            <Alert />
            <div>
              <h3>Edit Card</h3>
            </div>
            <div className="form-group">
              <label htmlFor="usr">Bank Name:</label>
              <input
                type="text"
                name="bank_name"
                value={bank_name}
                onChange={(e) => onChange(e)}
                className="form-control"
                id="usr"
              />
            </div>
            <div className="form-group">
              <label htmlFor="usr">Title:</label>
              <input
                type="text"
                name="title"
                value={title}
                onChange={(e) => onChange(e)}
                className="form-control"
                id="usr"
              />
            </div>
            <div className="form-group">
              <label htmlFor="pwd">Number:</label>
              <input
                type="text"
                name="number"
                value={number}
                onChange={(e) => onChange(e)}
                className="form-control"
                id="pwd"
              />
            </div>
            <div className="form-group">
              <label htmlFor="pwd">Expiry Date:</label>
              <input
                type="text"
                name="expiry_date"
                value={expiry_date}
                onChange={(e) => onChange(e)}
                className="form-control"
                id="pwd"
              />
            </div>

            <div className="form-group">
              <label htmlFor="pwd">Security Code:</label>
              <input
                type="text"
                name="security_code"
                value={security_code}
                onChange={(e) => onChange(e)}
                className="form-control"
                id="pwd"
              />
            </div>

            <br />
            <br />
            <div className="row justify-content-center">
              <div className="col-10">
                <input
                  type="submit"
                  className="btn btn-primary"
                  value="Update"
                  style={{
                    width: "40%",
                    marginLeft: "29%",
                    backgroundColor: "#5DADE2",
                  }}
                ></input>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

EditCard.prototypes = {
  setAlert: PropTypes.func.isRequired,
  updateCard: PropTypes.func.isRequired,
  getCardDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  categories: state.categories,
  cards: state.cards,
});

export default connect(mapStateToProps, {
  setAlert,
  updateCard,
  getCardDetails,
})(EditCard);
