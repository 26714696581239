import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateProfile } from "../../actions/auth";
import PropTypes from "prop-types";
import { setAlert } from "../../actions/alert";
import Alert from "../layout/Alert";
import { useHistory } from "react-router-dom";
import "./buyerSide.css";
import { loadUser, changePass } from "../../actions/auth";
import Spinner from "../layout/Spinner";

const MyAccountBuyer = ({
  updateProfile,
  loadUser,
  isAuthenticated,
  setAlert,
  changePass,
  loading,
  user,
}) => {
  console.log(user);
  const [formData, setFormData] = useState({
    name: "",
    last_name: "",
    company_name: "",
    phone: "",
    user_type: "",
    image: "",
  });
  const [passwordFormData, setPasswordFormData] = useState({
    old_password: "",
    password: "",
    password2: "",
  });
  useEffect(() => {
    if (user.user == null) {
      loadUser();
    }
    if (user.user != null) {
      setFormData({
        name: !user.user.name ? "" : user.user.name,
        last_name: !user.user.last_name ? "" : user.user.last_name,
        company_name: !user.user.company_name ? "" : user.user.company_name,
        phone: !user.user.phone ? "" : user.user.phone,
        image: !user.user.image ? "" : user.user.image,
        user_type: !user.user.user_type ? "" : user.user.user_type,
      });
    }
  }, [user]);
  const { name, last_name, company_name, phone, user_type, image } = formData;
  const { old_password, password, password2 } = passwordFormData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onFileChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });

  const onSubmit = async (e) => {
    e.preventDefault();
    updateProfile({ name, last_name, company_name, phone, image });
  };

  const onPasswordChange = (e) =>
    setPasswordFormData({
      ...passwordFormData,
      [e.target.name]: e.target.value,
    });

  const changePassword = async (e) => {
    e.preventDefault();
    console.log(old_password);
    if (password !== password2) {
      setAlert("Passwords doesn't match", "danger");
    } else {
      changePass(old_password, password);
    }
  };
  return loading === null ? (
    <Spinner />
  ) : (
    <>
      <div className="myaccountbuyer">
        <div className="myaccount">
          <h2 style={{ marginTop: "20px", marginLeft: "5%", color: "#0f3346" }}>
            My Account
          </h2>
          <img className="col-md-auto" style={{ width: "150px" }} src={image} />
          <Alert />
          <div
            className="my-acc"
            style={{
              width: "86%",
              borderBottom: "2px solid",
              marginLeft: "5%",
              color: "#0f3346",
            }}
          ></div>
        </div>
        <div className="myaccount">
          <h4 style={{ marginTop: "30px", marginLeft: "5%", color: "#0f3346" }}>
            Personal Information
          </h4>
          <div
            className="my-acc"
            style={{
              width: "86%",
              borderBottom: "2px solid",
              marginLeft: "5%",
              color: "#0f3346",
            }}
          ></div>
        </div>

        <form
          method="post"
          onSubmit={(e) => onSubmit(e)}
          style={{ marginTop: "15px" }}
          encType="multipart/form-data"
        >
          <div className="row">
            <div className="col-sm-6 ">
              <div className="form-group fr-co by-co">
                <label htmlFor="inputFirstName">First Name</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  className="form-control bc-cl"
                  onChange={(e) => onChange(e)}
                  id="inputFirstName"
                  required=""
                ></input>
              </div>
            </div>
            <div className="col-sm-6 ">
              <div className="form-group fr-co by-oo">
                <label htmlFor="inputLastName">Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  value={last_name}
                  onChange={(e) => onChange(e)}
                  className="form-control bc-cl"
                  id="inputLastName"
                  required=""
                ></input>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 ">
              <div className="form-group fr-co by-co">
                <label htmlFor="phoneName">Phone Number</label>
                <input
                  type="number"
                  name="phone"
                  className="form-control bc-cl"
                  onChange={(e) => onChange(e)}
                  value={phone}
                  id="phoneName"
                  required=""
                ></input>
              </div>
            </div>
            <div className="col-sm-6 ">
              <div className="form-group fr-co by-co">
                <input
                  type="file"
                  className="custom-file-input"
                  onChange={(e) => onFileChange(e)}
                  id="customFile"
                  name="image"
                />
                <label className="custom-file-label" htmlFor="customFile">
                  Choose Profile Image
                </label>
              </div>
            </div>
            {user_type == "Shop" ? (
              <div className="col-sm-6 ">
                <div className="form-group fr-co by-oo">
                  <label htmlFor="companyName">Company Name</label>
                  <input
                    type="text"
                    className="form-control bc-cl"
                    name="company_name"
                    onChange={(e) => onChange(e)}
                    value={company_name}
                    id="companyName"
                    required=""
                  ></input>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <input
            type="submit"
            className="btn btn-primary"
            value="Submit"
            style={{
              position: "absolute",
              width: "15%",
              right: "13%",
              backgroundColor: "#38b6f9",
              borderBlockColor: "#38b6f9",
            }}
          ></input>
        </form>

        <div className="myaccount">
          <h4 style={{ marginTop: "40px", marginLeft: "5%", color: "#0f3346" }}>
            Email Address
          </h4>
          <div
            className="my-acc"
            style={{
              width: "86%",
              borderBottom: "2px solid",
              marginLeft: "5%",
              color: "#0f3346",
            }}
          ></div>
        </div>

        <form
          action="/examples/actions/confirmation.php"
          method="post"
          style={{ marginTop: "15px" }}
        >
          <div className="row">
            <div className="col-sm-12 ">
              <div className="form-group by-co">
                <label htmlFor="email">Email</label>
                <input
                  readOnly
                  type="email"
                  className="form-control cn-e"
                  value={user.user.email}
                  id="email"
                ></input>
              </div>
            </div>
          </div>

          {/* <input
            type="submit"
            className="btn btn-primary"
            value="Submit"
            style={{
              position: "absolute",
              width: "15%",
              right: "13%",
              backgroundColor: "#38b6f9",
              borderBlockColor: "#38b6f9",
            }}
          ></input> */}
        </form>
        <Alert />
        <div className="myaccount">
          <h4 style={{ marginTop: "40px", marginLeft: "5%", color: "#0f3346" }}>
            Password
          </h4>
          <div
            className="my-acc"
            style={{
              width: "86%",
              borderBottom: "2px solid",
              marginLeft: "5%",
              color: "#0f3346",
            }}
          ></div>
        </div>

        <form
          method="post"
          // onSubmit={(e) => changePassword(e)}
          style={{ marginTop: "15px" }}
        >
          <div className="row">
            <div className="col-sm-6 ">
              <div className="form-group fr-co by-co">
                <label htmlFor="currentPassword">Current Password</label>
                <input
                  type="password"
                  name="old_password"
                  onChange={(e) => onPasswordChange(e)}
                  className="form-control bc-cl"
                  id="currentPassword"
                  required=""
                ></input>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 ">
              <div className="form-group fr-co by-co">
                <label htmlFor="newPassword">New Password</label>
                <input
                  type="password"
                  name="password"
                  onChange={(e) => onPasswordChange(e)}
                  className="form-control bc-cl"
                  id="newPassword"
                  required=""
                ></input>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 ">
              <div className="form-group fr-co by-co">
                <label htmlFor="cnPassword">Confirm New Password</label>
                <input
                  required
                  type="password"
                  name="password2"
                  onChange={(e) => onPasswordChange(e)}
                  className="form-control bc-cl"
                  id="cnPassword"
                  required=""
                ></input>
              </div>
            </div>
          </div>

          <input
            type="submit"
            className="btn btn-primary"
            value="Submit"
            onClick={(e) => changePassword(e)}
            style={{
              width: "15%",
              marginLeft: "60px",
              backgroundColor: "#38b6f9",
              borderBlockColor: "#38b6f9",
            }}
          ></input>
        </form>
      </div>
    </>
  );
};

MyAccountBuyer.prototypes = {
  setAlert: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  loadUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {
  loadUser,
  updateProfile,
  changePass,
  setAlert,
})(MyAccountBuyer);
