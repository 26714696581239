import React from "react";
import ContactUs from "./ContactUs";
import Email from "./Email";

const Contact = () => {
  return (
    <>
      <ContactUs />
      {/* <Email /> */}
    </>
  );
};

export default Contact;
