import axios from "axios";
import {
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  USER_LOADED,
  AUTH_ERROR,
  SET_ALERT,
  LOGOUT,
} from "./types";

import setAuthToken from "../utils/setAuthToken";
import { setAlert } from "../actions/alert";
import { v4 as uuid } from "uuid";

export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get(
      "https://cozillion.com/api/api/auth/me?token=" + localStorage.token
    );
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const register =
  ({
    name,
    last_name,
    email,
    password,
    user_type,
    company_name,
    phone,
    country,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      name,
      last_name,
      email,
      password,
      user_type,
      company_name,
      phone,
      country,
    });

    try {
      const res = await axios.post(
        "https://cozillion.com/api/api/auth/register",
        body,
        config
      );

      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
      dispatch(setAlert("Registered Successful", "success"));
      dispatch(login(email, password, user_type));
      //dispatch(loadUser());
    } catch (err) {
      if (err.response.status == "401") {
        dispatch(setAlert(err.response.data.message, "danger"));
      } else {
        for (var key in err.response.data.message.fields) {
          var obj = err.response.data.message.fields[key];
          dispatch(setAlert(obj[0], "danger"));
        }
      }
      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };

export const updateProfile =
  ({ name, last_name, company_name, phone, image }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // const body = JSON.stringify({
    //   name,
    //   last_name,
    //   company_name,
    //   phone,
    // });

    var bodyFormData = new FormData();
    bodyFormData.append("name", name);
    bodyFormData.append("last_name", last_name);
    bodyFormData.append("company_name", company_name);
    bodyFormData.append("phone", phone);
    bodyFormData.append("image", image);

    try {
      const res = await axios.post(
        "https://cozillion.com/api/api/auth/update_profile",
        bodyFormData,
        config
      );

      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
      dispatch(setAlert("Updated Successful", "success"));
      dispatch(loadUser());
    } catch (err) {
      // for (var key in err.response.data.message.fields) {
      //   var obj = err.response.data.message.fields[key];
      //   dispatch(setAlert(obj[0], "danger"));
      // }
      // dispatch({
      //   type: AUTH_ERROR,
      // });
    }
  };

export const changePass = (old_pass, new_pass) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({
    old_pass,
    new_pass,
  });

  try {
    const res = await axios.post(
      "https://cozillion.com/api/api/auth/change",
      body,
      config
    );

    // dispatch({
    //   type: USER_LOADED,
    //   payload: res.data,
    // });
    dispatch(setAlert("Updated Successful", "success"));
  } catch (err) {
    if (err.response.status == "404") {
      dispatch(setAlert(err.response.data.message, "danger"));
    } else {
      for (var key in err.response.data.message.fields) {
        var obj = err.response.data.message.fields[key];
        dispatch(setAlert(obj[0], "danger"));
      }
    }
  }
};

export const login = (email, password, user_type) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    email,
    password,
    user_type,
  });

  try {
    const res = await axios.post(
      "https://cozillion.com/api/api/auth/login",
      body,
      config
    );
    dispatch(setAlert("Login Successful", "success"));
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    if (err.response.status == "401") {
      dispatch(setAlert(err.response.data.message, "danger"));
    } else {
      for (var key in err.response.data.message.fields) {
        var obj = err.response.data.message.fields[key];
        dispatch(setAlert(obj[0], "danger"));
      }
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    email,
  });

  try {
    const res = await axios.post(
      "https://cozillion.com/api/api/auth/forgot",
      body,
      config
    );
    dispatch(setAlert("Reset Successfully!", "success"));
  } catch (err) {}
};

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};
