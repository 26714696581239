import axios from "axios";
import { setAlert } from "./alert";

import {
  GET_PROFILE,
  PROFILE_ERROR,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAIL,
  GET_CATEGORY,
  CATEGORY_ERROR,
  PRODUCTS_ERROR,
  GET_PRODUCTS,
  GET_PRODUCT,
  UPDATE_PRODUCTS,
  UPDATE_PRODUCT,
  CREATE_CARD_SUCCESS,
  CREATE_CARD_FAIL,
  UPDATE_CARDS,
  CARDS_ERROR,
  GET_CARDS,
  GET_CARD,
  CARD_ERROR,
  CREATE_ADDRESS_SUCCESS,
  CREATE_ADDRESS_FAIL,
  UPDATE_ADDRESSES,
  UPDATE_ADDRESS,
  ADDRESSES_ERROR,
  GET_ADDRESSES,
  GET_ADDRESS,
  ADDRESS_ERROR,
  SET_QUERY,
} from "./types";

export const getShopProducts = () => async (dispatch) => {
  try {
    const res = await axios.get("https://cozillion.com/api/api/auth/products");

    dispatch({
      type: GET_PRODUCTS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: PRODUCTS_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.data,
      },
    });
  }
};

export const getProductDetails = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      "https://cozillion.com/api/api/auth/product/" + id + "/edit"
    );

    dispatch({
      type: GET_PRODUCT,
      payload: res.data.product,
    });
  } catch (err) {
    dispatch({
      type: PRODUCTS_ERROR,
      payload: {
        msg: "error",
        status: "",
      },
    });
  }
};

export const getBuyerProductDetails = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      "https://cozillion.com/api/api/auth/product/" + id
    );

    dispatch({
      type: GET_PRODUCT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PRODUCTS_ERROR,
      payload: {
        msg: "error",
        status: "",
      },
    });
  }
};

export const queryProduct = (text) => async (dispatch) => {
  try {
    dispatch({
      type: SET_QUERY,
      payload: text,
    });
  } catch (err) {}
};

export const createProduct =
  ({ category_id, title, description, image, stock, price, sale }) =>
  async (dispatch) => {
    const config = {
      headers: {},
    };

    var bodyFormData = new FormData();
    bodyFormData.append("category_id", category_id);
    bodyFormData.append("title", title);
    bodyFormData.append("description", description);
    bodyFormData.append("image", image);
    bodyFormData.append("stock", stock);
    bodyFormData.append("price", price);
    bodyFormData.append("sale", sale);

    try {
      const res = await axios.post(
        "https://cozillion.com/api/api/auth/product",
        bodyFormData,
        config
      );

      dispatch({
        type: CREATE_PRODUCT_SUCCESS,
        payload: res.data,
      });
      dispatch(setAlert("Insert Successful", "success"));
    } catch (err) {
      if (err.response.status == "401") {
        dispatch(setAlert(err.response.data.message, "danger"));
      } else {
        for (var key in err.response.data.errors) {
          var obj = err.response.data.errors[key];
          dispatch(setAlert(obj[0], "danger"));
        }
      }
      dispatch({
        type: CREATE_PRODUCT_FAIL,
      });
    }
  };

export const updateProduct =
  ({
    product_id,
    category_id,
    title,
    description,
    image,
    stock,
    price,
    sale,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var bodyFormData = new FormData();
    bodyFormData.append("category_id", category_id);
    bodyFormData.append("title", title);
    bodyFormData.append("description", description);
    bodyFormData.append("image", image);
    bodyFormData.append("stock", stock);
    bodyFormData.append("sale", sale);
    bodyFormData.append("price", price);

    bodyFormData.append("_method", "PUT");

    try {
      const res = await axios.post(
        "https://cozillion.com/api/api/auth/product/" + product_id,
        bodyFormData,
        config
      );

      dispatch({
        type: UPDATE_PRODUCT,
        payload: res.data.product,
      });
      dispatch(setAlert("Update Successful", "success"));
    } catch (err) {
      if (err.response.status == "401") {
        dispatch(setAlert(err.response.data.message, "danger"));
      } else {
        for (var key in err.response.data.errors) {
          var obj = err.response.data.errors[key];
          dispatch(setAlert(obj[0], "danger"));
        }
      }
      dispatch({
        type: CREATE_PRODUCT_FAIL,
      });
    }
  };

export const deleteProduct = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `https://cozillion.com/api/api/auth/product/` + id
    );

    dispatch({
      type: UPDATE_PRODUCTS,
      payload: res.data.data,
    });

    dispatch(setAlert("Product Deleted", "success"));
  } catch (err) {
    console.log(err);
    dispatch({
      type: PRODUCTS_ERROR,
      payload: {
        msg: "ERROR",
        status: "ERROR",
      },
    });
  }
};

export const createCard =
  ({ bank_name, title, number, expiry_date, security_code }) =>
  async (dispatch) => {
    const config = {
      headers: {},
    };

    var bodyFormData = new FormData();
    bodyFormData.append("bank_name", bank_name);
    bodyFormData.append("title", title);
    bodyFormData.append("number", number);
    bodyFormData.append("expiry_date", expiry_date);
    bodyFormData.append("security_code", security_code);

    try {
      const res = await axios.post(
        "https://cozillion.com/api/api/auth/user_card",
        bodyFormData,
        config
      );

      dispatch({
        type: CREATE_CARD_SUCCESS,
        payload: res.data,
      });
      dispatch(setAlert("Insert Successful", "success"));
    } catch (err) {
      for (var key in err.response.data.errors) {
        var obj = err.response.data.errors[key];
        dispatch(setAlert(obj[0], "danger"));
      }
      dispatch({
        type: CREATE_CARD_FAIL,
      });
    }
  };

export const getCardDetails = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      "https://cozillion.com/api/api/auth/user_card/" + id
    );

    dispatch({
      type: GET_CARD,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: CARD_ERROR,
      payload: {
        msg: "error",
        status: "",
      },
    });
  }
};

export const updateCard =
  ({ card_id, bank_name, title, number, expiry_date, security_code }) =>
  async (dispatch) => {
    const config = {
      headers: {},
    };

    var bodyFormData = new FormData();
    bodyFormData.append("bank_name", bank_name);
    bodyFormData.append("title", title);
    bodyFormData.append("number", number);
    bodyFormData.append("expiry_date", expiry_date);
    bodyFormData.append("security_code", security_code);
    bodyFormData.append("_method", "PUT");

    try {
      const res = await axios.post(
        "https://cozillion.com/api/api/auth/user_card/" + card_id,
        bodyFormData,
        config
      );

      dispatch({
        type: CREATE_CARD_SUCCESS,
        payload: res.data,
      });
      dispatch(setAlert("Update Successful", "success"));
    } catch (err) {
      for (var key in err.response.data.errors) {
        var obj = err.response.data.errors[key];
        dispatch(setAlert(obj[0], "danger"));
      }
      dispatch({
        type: CREATE_CARD_FAIL,
      });
    }
  };

export const deleteCard = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `https://cozillion.com/api/api/auth/user_card/` + id
    );

    dispatch({
      type: UPDATE_CARDS,
      payload: res.data.user,
    });

    dispatch(setAlert("Product Deleted", "success"));
  } catch (err) {
    console.log(err);
    dispatch({
      type: CARDS_ERROR,
      payload: {
        msg: "ERROR",
        status: "ERROR",
      },
    });
  }
};

export const getUserCards = () => async (dispatch) => {
  try {
    const res = await axios.get("https://cozillion.com/api/api/auth/user_card");

    dispatch({
      type: GET_CARDS,
      payload: res.data.user[0].user_cards,
    });
  } catch (err) {
    dispatch({
      type: CARDS_ERROR,
      payload: {
        msg: "ERROR",
        status: "ERROR",
      },
    });
  }
};

// ADDRESS

export const createAddress =
  ({ user_address }) =>
  async (dispatch) => {
    const config = {
      headers: {},
    };

    var bodyFormData = new FormData();
    bodyFormData.append("address", user_address);

    try {
      const res = await axios.post(
        "https://cozillion.com/api/api/auth/address",
        bodyFormData,
        config
      );

      dispatch({
        type: CREATE_ADDRESS_SUCCESS,
        payload: res.data,
      });
      dispatch(setAlert("Inserted Successfully", "success"));
    } catch (err) {
      for (var key in err.response.data.errors) {
        var obj = err.response.data.errors[key];
        dispatch(setAlert(obj[0], "danger"));
      }
      dispatch({
        type: CREATE_ADDRESS_FAIL,
      });
    }
  };

export const getAddressDetails = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      "https://cozillion.com/api/api/auth/address/" + id
    );

    dispatch({
      type: GET_ADDRESS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: ADDRESS_ERROR,
      payload: {
        msg: "error",
        status: "",
      },
    });
  }
};

export const updateAddress =
  ({ address_id, user_address }) =>
  async (dispatch) => {
    const config = {
      headers: {},
    };

    var bodyFormData = new FormData();
    bodyFormData.append("address", user_address);
    bodyFormData.append("_method", "PUT");

    try {
      const res = await axios.post(
        "https://cozillion.com/api/api/auth/address/" + address_id,
        bodyFormData,
        config
      );

      dispatch({
        type: CREATE_ADDRESS_SUCCESS,
        payload: res.data,
      });
      dispatch(setAlert("Updated Successfully", "success"));
    } catch (err) {
      for (var key in err.response.data.errors) {
        var obj = err.response.data.errors[key];
        dispatch(setAlert(obj[0], "danger"));
      }
      dispatch({
        type: CREATE_ADDRESS_FAIL,
      });
    }
  };

export const deleteAddress = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `https://cozillion.com/api/api/auth/address/` + id
    );

    dispatch({
      type: GET_ADDRESSES,
      payload: res.data.user[0].addresses,
    });

    dispatch(setAlert("Address Deleted", "success"));
  } catch (err) {
    console.log(err);
    dispatch({
      type: ADDRESSES_ERROR,
      payload: {
        msg: "ERROR",
        status: "ERROR",
      },
    });
  }
};

export const getUserAddresses = () => async (dispatch) => {
  try {
    const res = await axios.get("https://cozillion.com/api/api/auth/address");

    dispatch({
      type: GET_ADDRESSES,
      payload: res.data.user[0].addresses,
    });
  } catch (err) {
    dispatch({
      type: ADDRESSES_ERROR,
      payload: {
        msg: "ERROR",
        status: "ERROR",
      },
    });
  }
};

export const getAllProducts =
  (q = "", cat = "") =>
  async (dispatch) => {
    try {
      const res = await axios.get(
        "https://cozillion.com/api/api/auth/product?q=" + q + "&cat=" + cat
      );

      dispatch({
        type: GET_PRODUCTS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({
        type: ADDRESSES_ERROR,
        payload: {
          msg: "ERROR",
          status: "ERROR",
        },
      });
    }
  };

export const contactUsApi =
  ({ fname, lname, description, email, role, phone }) =>
  async (dispatch) => {
    const config = {
      headers: {},
    };

    var bodyFormData = new FormData();
    bodyFormData.append("fname", fname);
    bodyFormData.append("lname", lname);
    bodyFormData.append("description", description);
    bodyFormData.append("email", email);
    bodyFormData.append("phone", phone);
    bodyFormData.append("role", role);

    try {
      const res = await axios.post(
        "https://cozillion.com/api/api/auth/contact-us",
        bodyFormData,
        config
      );
      dispatch(setAlert("Thank you", "success"));
    } catch (err) {
      console.log(err);
      for (var key in err.response.data.errors) {
        var obj = err.response.data.errors[key];
        dispatch(setAlert(obj[0], "danger"));
      }
    }
  };

export const subscribeNewsletter = (email) => async (dispatch) => {
  const config = {
    headers: {},
  };
  var bodyFormData = new FormData();
  bodyFormData.append("email", email);
  try {
    const res = await axios.post(
      "http://wavepalmapps.com/cozillion/api/public/api/subscribe",
      bodyFormData,
      config
    );

    dispatch(setAlert("Subscribed Successfully", "success"));
  } catch (err) {
    dispatch(setAlert("Error", "danger"));
  }
};
