import React, { Fragment } from "react";
import "./seller.css";
import { BrowserRouter as Router, NavLink, Route } from "react-router-dom";

import img from "../../imgs/Group 449.png";
import { FcHome, FcMoneyTransfer, FcNews } from "react-icons/fc";
import { FaHandsHelping } from "react-icons/fa";

const SellerSideBar = () => {
  return (
    <Fragment>
      <div className="col-2 desktop">
        <div className="row justify-content-center">
          <div className="col-10 pt-2">
            <img src={img} style={{ height: "100%", width: "100%" }} />
          </div>
        </div>
        <div className="col pt-3">
          <NavLink
            to="/Sel"
            exact
            className="link1"
            activeclassname="active1"
            className="l-b"
          >
            <span style={{ marginLeft: "10px" }}>
              <FcHome />
            </span>
            <span style={{ marginLeft: "10px" }}>Dashboard</span>
          </NavLink>
        </div>
        <div className="col pt-2">
          <NavLink
            to="/Sel/trns"
            exact
            className="link1"
            activeclassname="active1"
            className="l-b"
          >
            <span style={{ marginLeft: "10px" }}>
              <FcMoneyTransfer />
            </span>
            <span style={{ marginLeft: "10px" }}>Transactions</span>
          </NavLink>
        </div>
        <div className="col pt-2">
          <NavLink
            to="/Sel/pro"
            exact
            className="link1"
            activeclassname="active1"
            className="l-b"
          >
            <span style={{ marginLeft: "10px" }}>
              <FcNews />
            </span>
            <span style={{ marginLeft: "10px" }}>Products</span>
          </NavLink>
        </div>
        <div className="col pt-2">
          <NavLink
            to="/Sel/help"
            exact
            className="link1"
            activeclassname="active1"
            className="l-b"
          >
            <span style={{ marginLeft: "10px" }}>
              <FaHandsHelping />
            </span>
            <span style={{ marginLeft: "10px" }}>Help</span>
          </NavLink>
        </div>

        {/* <div className="col pt-2">
          <NavLink
            to="/Sel/add_product"
            exact
            className="link1"
            activeclassname="active1"
            className="l-b"
          >
            <span style={{ marginLeft: "10px" }}>
              <FaHandsHelping />
            </span>
            <span style={{ marginLeft: "10px" }}>Add Product</span>
          </NavLink>
        </div> */}
      </div>
    </Fragment>
  );
};

export default SellerSideBar;
