import React, { Fragment, useState, useEffect } from "react";
import "./buyerSide.css";
import { getUserOrders, updateItemStatus } from "../../actions/cart";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const OrderPage = ({
  cart: { cart, user_orders },
  getUserOrders,
  updateItemStatus,
}) => {
  useEffect(() => {
    getUserOrders();
  }, []);

  const ordersList = Object.keys(user_orders).map((order) => (
    <div key={user_orders[order].id} className="openpage">
      <div className="openpage-body">
        <div className="order-img">
          <img
            src={user_orders[order].product.image}
            style={{ height: "200px", width: "200px" }}
          />
        </div>
        <div className="order-title">{user_orders[order].product.title}</div>
        <div className="order-seller">
          Seller:{user_orders[order].cart.user.name}
        </div>
        <div className="order-price">${user_orders[order].price}</div>
        {user_orders[order].status == "Canceled" ? (
          ""
        ) : (
          <div
            className="cancelitem"
            onClick={(e) => setDeliveryStatus(user_orders[order].id, e)}
          >
            Cancel Item
          </div>
        )}

        <div className="buyagain">{user_orders[order].status}</div>
      </div>
    </div>
  ));

  function setDeliveryStatus(id, e) {
    updateItemStatus(id, "CANCELED");
    getUserOrders();
  }
  return (
    <Fragment>
      <div className="yourorder">
        <div className="myaccount">
          <h2 style={{ marginTop: "20px", marginLeft: "5%", color: "#365464" }}>
            Your Orders
          </h2>
          <div
            className="my-acc"
            style={{
              width: "86%",
              borderBottom: "2px solid #365464",
              marginLeft: "5%",
            }}
          ></div>
        </div>
        {/* <div className="pageorderlink">
          <div className="pol">Active Orders</div>
          <div className="pol" style={{ marginLeft: "3%" }}>
            Past Orders
          </div>
          <div className="pol" style={{ marginLeft: "3%" }}>
            Cancelled Orders
          </div>
          <div
            className="my-acc"
            style={{ width: "100%", borderBottom: "2px solid #c8c8c8" }}
          ></div>
        </div> */}

        {ordersList}
      </div>
    </Fragment>
  );
};

OrderPage.prototypes = {
  getUserOrders: PropTypes.func.isRequired,
  updateItemStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  cart: state.cart,
});

export default connect(mapStateToProps, { getUserOrders, updateItemStatus })(
  OrderPage
);
