import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import {
  BrowserRouter as Router,
  NavLink,
  Route,
  Link,
  Switch,
} from "react-router-dom";
import "./buyerSide.css";
import logo from "../../imgs/Group 449.png";
import { CgNotes } from "react-icons/cg";
import { GiShoppingCart } from "react-icons/gi";
import { connect } from "react-redux";
import { TiMessages } from "react-icons/ti";
import { queryProduct, getAllProducts } from "../../actions/profile";

const HelpHeader = ({
  auth: { user },
  profile: { query },
  queryProduct,
  getAllProducts,
}) => {
  const [formData, setFormData] = useState({
    searchText: "",
  });
  const { searchText } = formData;
  const history = useHistory();
  const onChange = (e) => {
    console.log("sdsd");
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    queryProduct(searchText);
    getAllProducts(searchText, []);
    history.push("/Buy/Products");
  };
  return (
    <Fragment>
      <div className="container-fluid mb-2">
        <div className="row justify-content-between">
          <div className="col-6">
            <div className="row justify-content-center">
              {/* <div className="3">
                <img
                  className="img-fluid"
                  src={logo}
                  style={{ width: "80%" }}
                />
              </div> */}
              <div className="col-11">
                <form
                  method="post"
                  onSubmit={(e) => onSubmit(e)}
                  encType="multipart/form-data"
                >
                  <div className="9 mt-4">
                    <div className="row">
                      <div className="col-2 ps-p">Product</div>
                      <div className="col-7 ps-i">
                        <input
                          type="text"
                          name="searchText"
                          value={searchText}
                          onChange={(e) => onChange(e)}
                          style={{
                            height: "",
                            paddingLeft: "",
                            width: "100%",
                            border: "2px solid  #315060",
                          }}
                        ></input>
                      </div>
                      <div className="col-3 ">
                        <input
                          type="submit"
                          className="ps-s"
                          value="Search"
                        ></input>
                      </div>

                      {/* <div className="col-3 ps-s">Search</div> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-5">
            <div className="row justify-content-around">
              <div className="col-5">
                <div className="row buyer-icon">
                  <div className="col-4">
                    <NavLink
                      to="/ShoppingBasket"
                      exact
                      className="link"
                      activeclassname="active"
                    >
                      {" "}
                      <GiShoppingCart />{" "}
                    </NavLink>
                  </div>
                  <div className="col-4">
                    <NavLink
                      to="/Buy/Orders"
                      exact
                      className="link"
                      activeclassname="active"
                    >
                      {" "}
                      <CgNotes />
                    </NavLink>
                  </div>
                  {/* <div className="col-4">
                    {" "}
                    <TiMessages />
                  </div> */}
                </div>
              </div>
              <div className="col-4 aa text-center mt-2 bck">
                <a>
                  {" "}
                  {user != null ? user.user.name : ""}{" "}
                  {user != null ? user.user.last_name : ""} <br />
                  <NavLink
                    to="/buyer/profile"
                    exact
                    className="link cl-w"
                    activeclassname="active"
                  >
                    My Account
                  </NavLink>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* part-2 */}

      <div className="container-fluid bck">
        <div className="container ">
          <div className="row justify-content-between">
            <div className="col-7">
              <div className="row pt-3 pb-3">
                <div className="col-2 ">
                  <NavLink
                    to="/Buy"
                    exact
                    className="link cl-w"
                    activeclassname="active"
                  >
                    {" "}
                    Home{" "}
                  </NavLink>
                </div>
                <div className="col-2 ">
                  <Link
                    to="/Buy/NewIn"
                    exact
                    className="link cl-w"
                    activeclassname="active"
                  >
                    {" "}
                    New In{" "}
                  </Link>
                </div>
                <div className="col-2 ">
                  <NavLink
                    to="/Buy/Products"
                    exact
                    className="link cl-w"
                    activeclassname="active"
                  >
                    {" "}
                    Products{" "}
                  </NavLink>
                </div>
                <div className="col-2 ">
                  <NavLink
                    to="/Buy/Wallet"
                    exact
                    className="link cl-w"
                    activeclassname="active"
                  >
                    {" "}
                    Wallet{" "}
                  </NavLink>
                </div>
                <div className="col-2 ">
                  <NavLink
                    to="/Buy/Products"
                    exact
                    className="link cl-w"
                    activeclassname="active"
                  >
                    {" "}
                    Supplier{" "}
                  </NavLink>
                </div>
                <div className="col-2 ">
                  <NavLink
                    to="/Buy/Help"
                    exact
                    className="link cl-w"
                    activeclassname="active"
                  >
                    {" "}
                    Help{" "}
                  </NavLink>
                </div>
                {/* <div className="col-2 ">
                  <NavLink
                    to="/Buy/Address"
                    exact
                    className="link"
                    activeclassname="active"
                  >
                    {" "}
                    Addresses{" "}
                  </NavLink>
                </div> */}
              </div>
            </div>
            <div className="col-5 pt-3">
              <NavLink
                to="/Buy/Address"
                exact
                className="link cl-w"
                activeclassname="active"
              >
                {" "}
                Addresses{" "}
              </NavLink>
            </div>
            {/* <div className="col-1 pt-1">
              <div className="">
                <button
                  type="button"
                  className="btn  dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ color: "white", fontSize: "1.2em" }}
                >
                  USD
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* part3 */}

      {/* <div className="alert  alert-dismissible text-center text bt-view alart-b">
          <button type="button" className="close" data-dismiss="alert">
            &times;
          </button>
          WELCOME BACK, USE CODE "GIVE23" FOR EXTRA 23% OFF YOUR ORDER
        </div> */}
    </Fragment>
  );
};

HelpHeader.prototypes = {
  queryProduct: PropTypes.func.isRequired,
  getAllProducts: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, { queryProduct, getAllProducts })(
  HelpHeader
);
