import React, { Fragment, useState, useEffect } from "react";
import img from "../../imgs/Image 153.png";
import { connect } from "react-redux";
import { getProductCategories } from "../../actions/category";
import auth from "../../reducers/auth";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";
import { createAddress } from "../../actions/profile";
import { setAlert } from "../../actions/alert";
import setAuthToken from "../../utils/setAuthToken";
import Alert from "../layout/Alert";
import HelpHeader from "./Help-Header";

const AddAddress = ({ setAlert, createAddress }) => {
  const [formData, setFormData] = useState({
    user_address: "",
  });

  const { user_address } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    createAddress({
      user_address,
    });
  };
  return (
    <Fragment>
      <HelpHeader />
      <div className="row bc-b9 pb-3 pt-3">
        <form
          method="post"
          onSubmit={(e) => onSubmit(e)}
          encType="multipart/form-data"
        >
          <div className="container">
            <Alert />
            <div>
              <h3>Add Address</h3>
            </div>
            <div className="form-group">
              <label htmlFor="usr">Address:</label>
              <input
                type="text"
                name="user_address"
                value={user_address}
                onChange={(e) => onChange(e)}
                className="form-control"
                id="usr"
              />
            </div>

            <br />
            <br />
            <div className="row justify-content-center">
              <div className="col-10">
                <input
                  type="submit"
                  className="btn btn-primary"
                  value="Insert"
                  style={{
                    width: "40%",
                    marginLeft: "29%",
                    backgroundColor: "#5DADE2",
                  }}
                ></input>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

AddAddress.prototypes = {
  setAlert: PropTypes.func.isRequired,
  createAddress: PropTypes.func.isRequired,
};

export default connect(null, {
  setAlert,
  createAddress,
})(AddAddress);
