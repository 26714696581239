import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import profile from "./profile";
import categories from "./category";
import cards from "./cards";
import addresses from "./address";
import cart from "./cart";

export default combineReducers({
  alert,
  auth,
  profile,
  categories,
  cards,
  addresses,
  cart,
});
