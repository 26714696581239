export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCT = "GET_PRODUCT";
export const PRODUCTS_ERROR = "PRODUCTS_ERROR";
export const GET_CATEGORY = "GET_CATEGORY";
export const CATEGORY_ERROR = "CATEGORY_ERROR";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const CREATE_PRODUCT_FAIL = "CREATE_PRODUCT_FAIL";

export const CREATE_CARD_SUCCESS = "CREATE_CARD_SUCCESS";
export const CREATE_CARD_FAIL = "CREATE_CARD_FAIL";
export const UPDATE_CARDS = "UPDATE_CARDS";
export const UPDATE_CARD = "UPDATE_CARD";
export const CARDS_ERROR = "CARDS_ERROR";
export const GET_CARDS = "GET_CARDS";
export const GET_CARD = "GET_CARD";
export const CARD_ERROR = "CARD_ERROR";

export const CREATE_ADDRESS_SUCCESS = "CREATE_ADDRESS_SUCCESS";
export const CREATE_ADDRESS_FAIL = "CREATE_ADDRESS_FAIL";
export const UPDATE_ADDRESSES = "UPDATE_ADDRESSES";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const ADDRESSES_ERROR = "ADDRESSES_ERROR";
export const GET_ADDRESSES = "GET_ADDRESSES";
export const GET_ADDRESS = "GET_ADDRESS";
export const ADDRESS_ERROR = "ADDRESS_ERROR";

export const CREATE_CART_SUCCESS = "CREATE_CART_SUCCESS";
export const CREATE_CART_FAIL = "CREATE_CART_FAIL";
export const UPDATE_CART = "UPDATE_CART";
export const GET_CART = "GET_CART";
export const CART_ERROR = "CART_ERROR";

export const GET_CART_ITEMS = "GET_CART_ITEMS";
export const EMPTY_CART = "EMPTY_CART";
export const GET_SHOP_ORDERS = "GET_SHOP_ORDERS";
export const GET_USER_ORDERS = "GET_USER_ORDERS";
export const SET_QUERY = "SET_QUERY";
