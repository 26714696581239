import React, { useEffect, Fragment } from "react";
import { Link, Redirect, NavLink } from "react-router-dom";
import "./buyerSide.css";
import visa from "../../imgs/visa13.png";
import { deleteCard, getUserCards } from "../../actions/profile";
import Spinner from "../layout/Spinner";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import HelpHeader from "./Help-Header";

const WalletPage = ({
  getUserCards,
  deleteCard,
  cards: { card, cards, loading },
}) => {
  useEffect(() => {
    getUserCards();
  }, [getUserCards]);

  const cardsList = cards.map((card) => (
    <div key={card.id} className="visawallet">
      <div className="visa-img">
        <img src={visa} style={{ width: "100%", height: "100%" }} />
      </div>
      <div className="visa-detail">
        <div>Name: {card.title}</div>
        <div>Card No: {card.number}</div>
        <div>Expiry Date: {card.expiry_date}</div>
        <div>CVV: {card.security_code}</div>
      </div>
      <div className="visa-btn">
        <Link className="btn-card" to={"card/edit/" + card.id}>
          Edit Card Details
        </Link>
        <br />
        <br />
        <div className="btn-card" onClick={() => deleteCard(card.id)}>
          Delete
        </div>
        <div style={{ marginTop: "11px" }}>
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          ></input>
          <label className="form-check-label" htmlFor="flexCheckDefault">
            Click to set default card
          </label>
        </div>
      </div>
    </div>
  ));

  return loading && card === null ? (
    <Spinner />
  ) : (
    <>
      <HelpHeader />
      <div
        className="walletpage"
        style={{ height: "400px", display: "inline" }}
      >
        <div className="myaccount">
          <h2
            style={{ marginLeft: "5%", color: "#274758", paddingTop: "20px" }}
          >
            Wallet
          </h2>
          <div
            className="my-acc"
            style={{
              width: "90%",
              borderBottom: "2px solid #0f3346",
              marginLeft: "5%",
            }}
          ></div>
        </div>
        {cardsList}
        <div className="row justify-content-center">
          <div className="col-10">
            <div className="addproducts">
              <NavLink
                to="/Buy/Wallet/Add"
                exact
                className="link"
                activeclassname="active"
              >
                {" "}
                Add Card{" "}
              </NavLink>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

WalletPage.prototypes = {
  getUserCards: PropTypes.func.isRequired,
  card: PropTypes.object.isRequired,
  deleteCard: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  cards: state.cards,
});

export default connect(mapStateToProps, { getUserCards, deleteCard })(
  WalletPage
);
