import React, { Fragment, useState, useEffect } from "react";
import "./seller.css";
import { Slide } from "react-slideshow-image";
import img1 from "../../imgs/Image 152.png";
import img2 from "../../imgs/Image 151.png";
import img3 from "../../imgs/Image 150.png";
import img4 from "../../imgs/Image 85.png";
import SellerSideBar from "./SellerSideBar";
import SellerHeader from "./SellerHeader";
import { getShopOrders, updateItemStatus } from "../../actions/cart";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const SupplierHome = ({
  updateItemStatus,
  getShopOrders,
  cart: { cart, shop_orders },
}) => {
  useEffect(() => {
    getShopOrders();
  }, []);

  const [searchText, setSearchText] = useState("");
  let ordersCount = 0;

  const ordersList = Object.keys(shop_orders).map((order) => {
    if (shop_orders[order].product == null) {
      return;
    }
    ordersCount = ordersCount + 1;
    return (
      <tr key={shop_orders[order].id}>
        <td>{shop_orders[order].id}</td>
        <td>{shop_orders[order].product.title}</td>
        <td>
          {shop_orders[order].cart.user.name}{" "}
          {shop_orders[order].cart.user.last_name}
        </td>
        <td>
          <select
            name="delivery_status"
            defaultValue={shop_orders[order].status}
            id="delivery_status"
            onChange={(e) => setDeliveryStatus(shop_orders[order].id, e)}
          >
            <option value="PROCESSING_STOCK">Processing Stock</option>
            <option value="READY_FOR_PACKING">Ready for Packing</option>
            <option value="READY_TO_DELIVER">Ready to deliver</option>
            <option value="DELIVERY_IN_PROGRESS">Delivery in progress</option>
            <option value="DELIVERED">Delivered</option>
            <option value="RETURNED">Returned</option>
            <option value="NOT_DELIVERED">Not Delivered</option>
          </select>
        </td>
      </tr>
    );
  });

  const recentBuyersList = Object.keys(shop_orders).map((order) => {
    if (shop_orders[order].product == null) {
      return;
    } else {
      return (
        <div key={shop_orders[order].id} className="row pt-2">
          <div className="media ml-1">
            <img
              src={shop_orders[order].cart.user.image}
              style={{ width: "26%" }}
            />
            <div className="media-body">
              <div className="row justify-content-around">
                <div className="col-5">
                  {shop_orders[order].cart.user.name}{" "}
                  {shop_orders[order].cart.user.last_name}
                </div>
                <div className="col-5">${shop_orders[order].price}</div>
              </div>
              <div className="row justify-content-around pt-2 text-center">
                <div className="col-4" id="item">
                  {shop_orders[order].product.title}
                </div>
                <div className="col-4" id="quantity">
                  {shop_orders[order].quantity}
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-11 pt-2">
                  <button type="button" id="status">
                    Status
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  });

  function setDeliveryStatus(id, e) {
    updateItemStatus(id, e.target.value);
    getShopOrders();
  }

  const searchSupplierOrder = () => {
    getShopOrders(searchText);
  };
  return (
    <Fragment>
      <SellerHeader />
      <div className="row ">
        <SellerSideBar />
        <div className="col-12 col-sm-10 hel">
          <div className="row bc-b9 pb-3">
            <div className="container">
              <div className="row mt-2  tran-head rowleft">Dashboard</div>
              {/* Desktop */}
              <div className="row mt-2 desktop">
                <div className="col-4 ">
                  <div className="row ">
                    <div className="col-10 bo-rd bck text-center">
                      <div className="media">
                        <img
                          src={img1}
                          style={{ width: "30%" }}
                          className="mt-3"
                        />
                        <div className="media-body  cl-w mt-2">
                          <h4>Products</h4>
                          <h2>+30</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-4 ">
                  <div className="row ">
                    <div className="col-10 bo-rd text-center bc-38 ">
                      <div className="media">
                        <img
                          src={img2}
                          style={{ width: "30%" }}
                          className="mt-2"
                        />
                        <div className="media-body cl-w mt-2">
                          <h4>New Orders</h4>
                          <h2>+{ordersCount}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-4 ">
                  <div className="row ">
                    <div className="col-10 bo-rd bck text-center">
                      <div className="media">
                        <img
                          src={img3}
                          style={{ width: "30%" }}
                          className="mt-2"
                        />
                        <div className="media-body cl-w mt-2">
                          <h4>Impressions</h4>
                          <h2>+30</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Mobile */}
              <div className="slider2 mobile">
                <div className="slide-container">
                  <Slide>
                    <div className="row ">
                      <div className="col-11 bo-rd bck text-center">
                        <div className="media">
                          <img
                            src={img1}
                            style={{ width: "30%" }}
                            className="mt-3"
                          />
                          <div className="media-body  cl-w mt-2">
                            <h4>Products</h4>
                            <h2>+30</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* slider2 */}
                    <div className="row ">
                      <div className="col-11 bo-rd text-center bc-38 ">
                        <div className="media">
                          <img
                            src={img2}
                            style={{ width: "30%" }}
                            className="mt-2"
                          />
                          <div className="media-body cl-w mt-2">
                            <h4>New Orders</h4>
                            <h2>+{ordersCount}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* slider 3 */}
                    <div className="row ">
                      <div className="col-11 bo-rd bck text-center">
                        <div className="media">
                          <img
                            src={img3}
                            style={{ width: "30%" }}
                            className="mt-2"
                          />
                          <div className="media-body cl-w mt-2">
                            <h4>Impressions</h4>
                            <h2>+30</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slide>
                </div>
              </div>
              {/* mobile end */}

              <div className="row justify-content-around">
                <div className="col-12 col-sm-7 mt-4 b1-b9">
                  <div className="row pt-2 bottomline">
                    <div
                      className="col-12 
                 "
                    >
                      <div className=" px42 fwb">Recent Orders</div>
                    </div>
                  </div>
                  <div className="row justify-content-between pt-3">
                    <div className="col-10 col-sm-3 b1-r text-center ml-3">
                      {ordersCount} Total Orders
                    </div>
                    {/* <div className="col-4  px18 ml-3">View Order Below</div> */}
                    <div className="col-6 desktop">
                      <span>
                        <input
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          type="text"
                        ></input>
                      </span>
                      <span>
                        <input
                          type="button"
                          value="Search"
                          onClick={() => {
                            searchSupplierOrder();
                          }}
                          style={{
                            backgroundColor: "#e20613",
                            borderColor: "#e20613",
                            color: "white",
                          }}
                        ></input>
                      </span>
                    </div>
                  </div>
                  <div className="row pt-3 pxmob">
                    <table className="table">
                      <thead>
                        <tr
                          style={{ backgroundColor: "#274758", color: "white" }}
                        >
                          <th scope="col">Product ID</th>
                          <th scope="col">Invoice Number</th>
                          <th scope="col">Buyer</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>{ordersList}</tbody>
                    </table>
                  </div>
                </div>
                <div className="col-12 col-sm-4 b1-b9 mt-4">
                  <div className="row pt-2">
                    <div className="col-12 bb-f3">
                      <div className="px42 fwb">Recent Buyers</div>
                    </div>
                  </div>

                  {/* <div className="row pt-2">
                    <div className="media ml-1">
                      <img src={img4} style={{ width: "26%" }} />
                      <div className="media-body">
                        <div className="row justify-content-around">
                          <div className="col-5">Buyer Name</div>
                          <div className="col-5">$1000055</div>
                        </div>
                        <div className="row justify-content-around pt-2 text-center">
                          <div className="col-4" id="item">
                            Item
                          </div>
                          <div className="col-4" id="quantity">
                            Quantity
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-11 pt-2">
                            <button type="button" id="status">
                              Status
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {recentBuyersList}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

SupplierHome.prototypes = {
  getShopOrders: PropTypes.func.isRequired,
  updateItemStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  cart: state.cart,
});

export default connect(mapStateToProps, {
  getShopOrders,
  updateItemStatus,
})(SupplierHome);
