import React from "react";
import "./seller.css";
import img1 from "../../imgs/Image 152.png";
import img2 from "../../imgs/Image 151.png";
import img3 from "../../imgs/Image 150.png";
import img4 from "../../imgs/Image 85.png";
import SellerSideBar from "./SellerSideBar";
import SellerHeader from "./SellerHeader";

const SupplierTransaction = () => {
  return (
    <>
      <SellerHeader />
      <div className="row ">
        <SellerSideBar />
        <div className="col-10 hel">
          <div className="row bc-b9 pb-3">
            <div className="container">
              <div className="row mt-2  tran-head">Transactions</div>
              <div className="row mt-4">
                <div className="col-4">
                  <div className="row justify-content-center">
                    <div className="col-10 bck text-center bo-rd">
                      <div className="media">
                        <img
                          src={img1}
                          style={{ width: "30%" }}
                          className="mt-3"
                        />
                        <div className="media-body cl-w mt-2 ">
                          <h4>Products</h4>
                          <h2>+30</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="row justify-content-center">
                    <div className="col-10 text-center bc-38 bo-rd">
                      <div className="media">
                        <img
                          src={img2}
                          style={{ width: "30%" }}
                          className="mt-2"
                        />
                        <div className="media-body cl-w mt-2 ">
                          <h4>Total Orders</h4>
                          <h2>4000</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="row justify-content-center">
                    <div className="col-10 bck text-center bo-rd">
                      <div className="media">
                        <img
                          src={img3}
                          style={{ width: "30%" }}
                          className="mt-2"
                        />
                        <div className="media-body cl-w mt-2 ">
                          <h5>Total Impressions</h5>
                          <h2>4000</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-around">
                <div className="col-7 mt-4 b1-b9">
                  <div className="row pt-2">
                    <div className="col-12 bb-f3">
                      <div className=" px50 fwb">Orders</div>
                    </div>
                  </div>
                  <div className="row justify-content-between pt-3">
                    <div className="col-4  px18 ml-3">View Order Below</div>
                    <div className="col-6">
                      <span>
                        <input type="text"></input>
                      </span>
                      <span>
                        <input
                          type="button"
                          value="Search"
                          style={{
                            backgroundColor: "#037BBE",
                            borderColor: "#037BBE",
                            color: "white",
                          }}
                        ></input>
                      </span>
                    </div>
                  </div>
                  <div className="row pt-3">
                    <table className="table">
                      <thead>
                        <tr
                          style={{ backgroundColor: "#274758", color: "white" }}
                        >
                          <th scope="col">Product ID</th>
                          <th scope="col">Invoice Number</th>
                          <th scope="col">Buyer</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>111000</td>
                          <td>958635</td>
                          <td>BuyerName</td>
                          <td>
                            <input
                              type="button"
                              value="Start"
                              style={{
                                width: "90px",
                                backgroundColor: "#e20613",
                                borderColor: "#e20613",
                                color: "white",
                              }}
                            ></input>
                          </td>
                        </tr>
                        <tr>
                          <td>111000</td>
                          <td>958635</td>
                          <td>BuyerName</td>
                          <td>
                            <input
                              type="button"
                              value="Processing"
                              style={{
                                width: "90px",
                                backgroundColor: "#38b6f9",
                                borderColor: "#38b6f9",
                                color: "white",
                              }}
                            ></input>
                          </td>
                        </tr>
                        <tr>
                          <td>111000</td>
                          <td>958635</td>
                          <td>BuyerName</td>
                          <td>
                            <input
                              type="button"
                              value="Start"
                              style={{
                                width: "90px",
                                backgroundColor: "#e20613",
                                borderColor: "#e20613",
                                color: "white",
                              }}
                            ></input>
                          </td>
                        </tr>
                        <tr>
                          <td>111000</td>
                          <td>958635</td>
                          <td>BuyerName</td>
                          <td>
                            <input
                              type="button"
                              value="Start"
                              style={{
                                width: "90px",
                                backgroundColor: "#e20613",
                                borderColor: "#e20613",
                                color: "white",
                              }}
                            ></input>
                          </td>
                        </tr>
                        <tr>
                          <td>111000</td>
                          <td>958635</td>
                          <td>BuyerName</td>
                          <td>
                            <input
                              type="button"
                              value="Processing"
                              style={{
                                width: "90px",
                                backgroundColor: "#38b6f9",
                                borderColor: "#38b6f9",
                                color: "white",
                              }}
                            ></input>
                          </td>
                        </tr>
                        <tr>
                          <td>111000</td>
                          <td>958635</td>
                          <td>BuyerName</td>
                          <td>
                            <input
                              type="button"
                              value="Start"
                              style={{
                                width: "90px",
                                backgroundColor: "#e20613",
                                borderColor: "#e20613",
                                color: "white",
                              }}
                            ></input>
                          </td>
                        </tr>
                        <tr>
                          <td>111000</td>
                          <td>958635</td>
                          <td>BuyerName</td>
                          <td>
                            <input
                              type="button"
                              value="Start"
                              style={{
                                width: "90px",
                                backgroundColor: "#e20613",
                                borderColor: "#e20613",
                                color: "white",
                              }}
                            ></input>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-4 b1-b9 mt-4">
                  <div className="row pt-2">
                    <div className="col-12 bb-f3">
                      <div className="px50 fwb">Buyers</div>
                    </div>
                  </div>

                  <div className="row pt-2">
                    <div className="media ml-1">
                      <img src={img4} style={{ width: "26%" }} />
                      <div className="media-body">
                        <div className="row justify-content-around">
                          <div className="col-5">Buyer Name</div>
                          <div className="col-5">$1000055</div>
                        </div>
                        <div className="row justify-content-around pt-2 text-center">
                          <div className="col-4" id="item">
                            Item
                          </div>
                          <div className="col-4" id="quantity">
                            Quantity
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-11 pt-2">
                            <button type="button" id="status">
                              Status
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row pt-2">
                    <div className="media ml-1">
                      <img src={img4} style={{ width: "26%" }} />
                      <div className="media-body">
                        <div className="row justify-content-around">
                          <div className="col-5">Buyer Name</div>
                          <div className="col-5">$1000055</div>
                        </div>
                        <div className="row justify-content-around pt-2 text-center">
                          <div className="col-4" id="item">
                            Item
                          </div>
                          <div className="col-4" id="quantity">
                            Quantity
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-11 pt-2">
                            <button type="button" id="status">
                              Status
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row pt-2">
                    <div className="media ml-1">
                      <img src={img4} style={{ width: "26%" }} />
                      <div className="media-body">
                        <div className="row justify-content-around">
                          <div className="col-5">Buyer Name</div>
                          <div className="col-5">$1000055</div>
                        </div>
                        <div className="row justify-content-around pt-2 text-center">
                          <div className="col-4" id="item">
                            Item
                          </div>
                          <div className="col-4" id="quantity">
                            Quantity
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-11 pt-2">
                            <button type="button" id="status">
                              Status
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row pt-2">
                    <div className="media ml-1">
                      <img src={img4} style={{ width: "26%" }} />
                      <div className="media-body">
                        <div className="row justify-content-around">
                          <div className="col-5">Buyer Name</div>
                          <div className="col-5">$1000055</div>
                        </div>
                        <div className="row justify-content-around pt-2 text-center">
                          <div className="col-4" id="item">
                            Item
                          </div>
                          <div className="col-4" id="quantity">
                            Quantity
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-11 pt-2">
                            <button type="button" id="status">
                              Status
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row pt-2">
                    <div className="media ml-1">
                      <img src={img4} style={{ width: "26%" }} />
                      <div className="media-body">
                        <div className="row justify-content-around">
                          <div className="col-5">Buyer Name</div>
                          <div className="col-5">$1000055</div>
                        </div>
                        <div className="row justify-content-around pt-2 text-center">
                          <div className="col-4" id="item">
                            Item
                          </div>
                          <div className="col-4" id="quantity">
                            Quantity
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-11 pt-2">
                            <button type="button" id="status">
                              Status
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupplierTransaction;
