import React, { Fragment, useState, useEffect } from "react";
import "./cozillion.css";
import { contactUsApi } from "../actions/profile";
import PropTypes from "prop-types";
import { setAlert } from "../actions/alert";
import imag121 from "../imgs/contactimg.png";
import { connect } from "react-redux";
import Alert from "./layout/Alert";

const ContactUs = ({ contactUsApi }) => {
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    description: "",
    email: "",
    role: "",
    phone: "",
  });

  const { fname, lname, description, email, phone, role } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    contactUsApi({
      fname,
      lname,
      description,
      email,
      role,
      phone,
    });
  };
  return (
    <>
      <div className="container">
        <div className="row mt-5">
          <div className="col text-center px42 fwb hel cl-57">
            Contact Our Team
          </div>
        </div>
        <div className="row justify-content-around mt-5">
          <div className="col-12 col-sm-6">
            <img src={imag121} style={{ width: "100%", height: "100%" }}></img>
          </div>
          <div className="col-11 col-sm-5 hel bor">
            <div className="row justify-content-center pt-5 pb-5">
              <div className="col-10">
                <Alert />
                <form onSubmit={(e) => onSubmit(e)} method="post">
                  <div className="row">
                    <div className="col-sm-12 ">
                      <div className="form-group ">
                        <label htmlFor="email">First Name</label>
                        <input
                          name="fname"
                          value={fname}
                          onChange={(e) => onChange(e)}
                          type="text"
                          className="form-control cn-e"
                          id="inputFirstName"
                          required=""
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 ">
                      <div className="form-group ">
                        <label htmlFor="inputLastName">Last Name</label>
                        <input
                          type="text"
                          name="lname"
                          value={lname}
                          onChange={(e) => onChange(e)}
                          className="form-control cn-e"
                          id="inputLastName"
                          required=""
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 ">
                      <div className="form-group ">
                        <label htmlFor="email">Email Address</label>
                        <input
                          name="email"
                          value={email}
                          onChange={(e) => onChange(e)}
                          type="email"
                          className="form-control cn-e"
                          id="email"
                          required=""
                        ></input>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group ">
                        <label htmlFor="help">Enquiry</label>

                        <textarea
                          name="description"
                          value={description}
                          onChange={(e) => onChange(e)}
                          className="form-control cn-e"
                          id="exampleFormControlTextarea1"
                          rows="5"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <input
                    type="submit"
                    className="btn bc03 cl-w px20 fwb "
                    value="Submit"
                  ></input>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row justify-content-center"
          style={{ marginTop: "100px" }}
        >
          <div className="col-6 borderbottom"></div>
        </div>
      </div>
      <div className="container-fluid tb">
        <div className="row mt-5 justify-content-around hel">
          <div className="col-10 col-sm-3 text-center bor box-4">
            <div className="px28 fwb mt-5">Need Help?</div>
            <div className="px20 mt-3">
              Need phone support or have <br />
              questions about how Cozillion?
            </div>
            <div className="mt-3 mb-5">
              <button className="btn px20 fwb bc0b cl-w">Get Started</button>
            </div>
          </div>
          <div className="col-10 col-sm-3 text-center bor box-4 mmt-2">
            <div className="px28 fwb mt-5">Want to be a supplier?</div>
            <div className="px20 mt-3">
              Need phone support or have questions
              <br /> about how Cozillion?
            </div>
            <div className="mt-3 mb-5">
              <button className="btn px20 fwb bc0b cl-w">Get Started</button>
            </div>
          </div>
          <div className="col-10 col-sm-3 text-center bor box-4 mmt-2">
            <div className="px28 fwb mt-5">Schedule a Call</div>
            <div className="px20 mt-3">
              Need phone support or have questions
              <br /> about how Cozillion?
            </div>
            <div className="mt-3 mb-5">
              <button className="btn px20 fwb bc0b cl-w">Get Started</button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid ptb bcd7">
        <div className="row px20 hel">
          <div className="col-12 col-sm-4 text-center">
            Owner Name <br />
            Number: 04858 292 566 <br />
            E: owneremail@gmail.co.uk
          </div>
          <div className="col-12 col-sm-4 text-center mmt-2">
            POS Box: <br />
            43 Harvest Road <br />
            Lekki, Nigeria
          </div>
          <div className="col-12 col-sm-4 text-center mmt-2">
            Name
            <br />
            Number: 04858 292 566
            <br />
            E: owneremail@gmail.co.uk
          </div>
        </div>
      </div>
    </>
  );
};

ContactUs.prototypes = {
  contactUsApi: PropTypes.func.isRequired,
};

export default connect(null, {
  contactUsApi,
})(ContactUs);
