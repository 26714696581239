import React, { useEffect, Fragment } from "react";
import "./seller.css";
import img from "../../imgs/Image 153.png";
import { connect } from "react-redux";
import { getShopProducts } from "../../actions/profile";
import { Link, Redirect } from "react-router-dom";
import auth from "../../reducers/auth";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";
import setAuthToken from "../../utils/setAuthToken";
import { deleteProduct } from "../../actions/profile";
import SellerSideBar from "./SellerSideBar";
import SellerHeader from "./SellerHeader";

const editProduct = (id) => {
  <Redirect to={"Sel/pro"} />;
};

const SupplierProducts = ({
  getShopProducts,
  auth,
  deleteProduct,
  profile: { profile, products, loading },
}) => {
  useEffect(() => {
    getShopProducts();
  }, [getShopProducts]);

  const productsList = products.map((product) => (
    <div key={product.id} className="col-3">
      <div className="row">
        <div className="col-12 br-b9">
          <div className="row">
            <div className="col-12">
              <img src={product.image} className="prod1img" />
            </div>
          </div>
          <div className="prod1-del">
            <Link to={"product/edit/" + product.id}>Edit</Link>
          </div>
          <div className="prod1-title">{product.title}</div>
          <div className="prod1-des">Product Description</div>
          <div className="prod1-text">{product.description}</div>
          <div
            className="my-acc"
            style={{
              width: "90%",
              borderBottom: "1px solid #707070",
              marginLeft: "5%",
              marginTop: "40px",
            }}
          ></div>
          <div onClick={() => deleteProduct(product.id)} className="prod1-del">
            {"   "}Delete{"   "}
          </div>
        </div>
      </div>
    </div>
  ));

  return loading && profile === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <SellerHeader />
      <div className="row ">
        <SellerSideBar />
        <div className="col-10">
          <div className="row bc-b9 pb-3 pt-3">
            <div className="col-10 bb-f3">
              <h3>Products</h3>
            </div>
            <div className="container-fluid">
              <div className="row pt-3 justify-content-around">
                {productsList}
              </div>
              <div className="row justify-content-center">
                <div className="col-10">
                  <div className="addproducts">
                    <Link to="add_product">Add Products</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

SupplierProducts.prototypes = {
  getShopProducts: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  deleteProduct: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps, { getShopProducts, deleteProduct })(
  SupplierProducts
);
