import { React, Fragment, useState } from "react";
import "../App.css";
import { FaAlignRight } from "react-icons/fa";
import {
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import {
  BrowserRouter as Router,
  NavLink,
  Route,
  Link,
} from "react-router-dom";
import logo from "../imgs/Group 449.png";

import { connect } from "react-redux";
import { logout } from "../actions/auth";
import PropTypes from "prop-types";

const Header = ({ auth: { isAuthenticated }, logout }) => {
  const [toggle, setToggle] = useState(false);

  const authLinks = (
    <>
      <div className="col-2 col-p px30 pt">
        <a onClick={logout}>Logout</a>
      </div>
    </>
  );

  const guestLinks = (
    <>
      <div className="col-12 col-sm-2 col-p px30 pt">
        <NavLink to="/buyer" className="link" activeclassname="active">
          Buyer
        </NavLink>
      </div>
      <div className="col-12 col-sm-2 col-p px30 pt">
        <NavLink to="/seller" className="link" activeclassname="active">
          Seller
        </NavLink>
      </div>
      <div className="col-12 col-sm-2 col-p px30 pt">
        <NavLink to="/contact" className="link" activeclassname="active">
          Contact
        </NavLink>
      </div>
      <div className="col-12 col-sm-2 ">
        <div className="s1 container-fluid" style={{ cursor: "pointer" }}>
          <div className="signup text-center pop px18">
            <Link
              to="/buyer/signup"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              Sign up
            </Link>
          </div>
        </div>
      </div>
    </>
  );
  const Toggle = () => {
    setToggle(true);
    console.log(toggle);
  };
  const Toggle2 = () => {
    setToggle(false);
    console.log(toggle);
  };
  return (
    <Fragment>
      <div className="alert bck alert-dismissible text-center px16 hel cl-w ">
        <button type="button" className="close" data-dismiss="alert">
          &times;
        </button>
        Cozillion gives you access to the best suppliers. See how it works
      </div>
      {/* desktop view */}
      <div className="container-fluid desktop">
        <div className="row">
          <div className="col-4">
            <div className="row">
              <div className="col-6">
                <img src={logo} alt="Logo" style={{ width: "100%" }} />
              </div>
            </div>
          </div>
          <div className="col-2"></div>
          <div className="col-6">
            <div className="row justify-content-end">
              <div className="col-2 col-p px30 pt">
                <NavLink to="/" exact className="link">
                  Home
                </NavLink>
              </div>
              <div className="col-2 col-p px30 pt">
                <NavLink to="/about" className="link">
                  About
                </NavLink>
              </div>
              {isAuthenticated ? authLinks : guestLinks}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile view */}
      <div className="container-fluid mobile">
        <div className="row justify-content-between">
          <div className="col-4">
            <img src={logo} alt="Logo" style={{ width: "100%" }} />
          </div>
          <div className="col-2">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                Toggle();
              }}
            >
              <FaAlignRight />
            </button>
          </div>
        </div>
      </div>
      <div>
        <Modal
          isOpen={toggle}
          toggle={() => {
            Toggle2();
          }}
        >
          <ModalHeader
            toggle={() => {
              Toggle2();
            }}
          ></ModalHeader>
          <ModalBody>
            <div className="container-fluid ">
              <div className="row">
                <div className="col-12 col-p px30 pt">
                  <NavLink to="/" exact className="link">
                    Home
                  </NavLink>
                </div>
                <div className="col-12 col-p px30 pt">
                  <NavLink to="/about" className="link">
                    About
                  </NavLink>
                </div>
                {isAuthenticated ? authLinks : guestLinks}
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </Fragment>
  );
};

Header.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Header);
