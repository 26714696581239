import React from "react";
import "./seller.css";
import SellerSideBar from "./SellerSideBar";
import SellerHeader from "./SellerHeader";

const HelpCentre = () => {
  return (
    <>
      <SellerHeader />
      <div className="row ">
        <SellerSideBar />
        <div className="col-10">
          <div className="row bc-b9 pb-3 pt-4">
            <div className="container">
              <div className="row bb-f3">
                <h3>Help Center</h3>
              </div>
              <div className="row justify-content-center">
                <div className="col-10 pt-4 pb-4">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                    ></input>
                    <div className="input-group-append">
                      <button className="btn bck cl-w" type="submit">
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-4 text-center">
                  <h4>Topics</h4>
                </div>
              </div>

              <div className="row text-center pt-3">
                <div className="col-3 bck cl-w pt-2 pb-2">
                  <h5>My Account</h5>
                </div>
                <div className="col-3 bc-e5 pt-2 pb-2">
                  <h5>Purchases</h5>
                </div>
                <div className="col-3 bck cl-w pt-2 pb-2">
                  <h5>Wallet</h5>
                </div>
                <div className="col-3 bc-e5 pt-2 pb-2">
                  <h5>Delivery</h5>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="sellerhelp"> */}
          {/* <div className="myaccount">
                <h2 style={{marginLeft:"5%",color:"#274758"}}>Help Center</h2>
                <div className="my-acc" style={{width:"86%",borderBottom:"2px solid #0f3346",marginLeft:"5%"}}></div>
             </div> */}
          {/* <div className="help-text">Help Centre</div>
            <div className="help-img"> */}
          {/* <img src={img}  /> */}
          {/* <div className="help-search">
                     <input className="form-control form-control-sm" type="text" style={{width:"70%",marginLeft:"10%",marginTop:"35px",borderRadius:"15px"}}></input>
                     <div className="search-btn" style={{left:"75%"}}>Search</div>
                </div>
            </div>
            <div style={{textAlign:"center",fontSize:"3em",color:"#16394c"}}>Topics</div>
            <div className="topic" style={{height:"390px"}}>
                <div className="to-he" style={{backgroundColor:"#0f3346",color:"white"}}>My Account</div>
                <div className="to-he" style={{backgroundColor:"#e5f6ff",color:"#0f3346"}}>Purchases</div>
                <div className="to-he" style={{backgroundColor:"#0f3346",color:"white"}}>Wallet</div>
                <div className="to-he" style={{backgroundColor:"#e5f6ff",color:"#0f3346"}}>Delivery</div>

            </div>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default HelpCentre;
