import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import "../cozillion.css";
import { FaTwitterSquare, FaFacebookSquare, FaLinkedin } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import { register } from "../../actions/auth";
import PropTypes from "prop-types";
import Alert from "../layout/Alert";
import { useHistory } from "react-router-dom";

const SignUp = ({ setAlert, register, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    last_name: "",
    password: "",
    password2: "",
    user_type: "User",
    company_name: "",
    phone: "",
    country: "",
  });

  const {
    name,
    last_name,
    email,
    password,
    password2,
    user_type,
    company_name,
    phone,
    country,
  } = formData;

  const [checked, setChecked] = useState(false);
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      setAlert("Passwords doesn't match", "danger");
    } else if (!checked) {
      setAlert("Please accept terms and conditions", "danger");
    } else {
      register({ name, last_name, email, password, user_type });
    }
  };
  const history = useHistory();
  //Redirect if authenticated
  if (isAuthenticated) {
    setTimeout(function () {
      history.push("/buy");
    }, 1000);
  }
  return (
    <Fragment>
      <div className=".container-fluid sign-con mt-4 login-image">
        <div className="container ptb hel">
          <div className="row justify-content-center ">
            <div className="col-11 col-sm-8 sellerform pt-3 pb-3">
              <div className="px42 text-center cl-57 pb-4">Buyer's Sign Up</div>
              <Alert />
              <form method="post" onSubmit={(e) => onSubmit(e)}>
                <div className="row">
                  <div className=" col-sm-6 ">
                    <div className="form-group  ">
                      <label htmlFor="inputFirstName">First Name</label>
                      <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={(e) => onChange(e)}
                        className="form-control bce5 "
                        id="inputFirstName"
                        required=""
                      ></input>
                    </div>
                  </div>
                  <div className=" col-sm-6 ">
                    <div className="form-group  by-oo">
                      <label htmlFor="inputLastName">Last Name</label>
                      <input
                        type="text"
                        className="form-control bce5"
                        name="last_name"
                        value={last_name}
                        onChange={(e) => onChange(e)}
                        id="inputLastName"
                        required=""
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 ">
                    <div className="form-group  ">
                      <label htmlFor="emailaddress">Email Address</label>
                      <input
                        type="email"
                        className="form-control bce5 "
                        name="email"
                        value={email}
                        onChange={(e) => onChange(e)}
                        id="emailaddress"
                        required=""
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-6 ">
                    <div className="form-group  by-oo">
                      <label htmlFor="phonenumber">Phone Number</label>
                      <input
                        type="number"
                        className="form-control bce5 "
                        name="phone"
                        value={phone}
                        onChange={(e) => onChange(e)}
                        id="phonenumber"
                        required=""
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 ">
                    <div className="form-group  ">
                      <label htmlFor="country">Country</label>
                      <input
                        type="text"
                        className="form-control bce5"
                        name="country"
                        value={country}
                        onChange={(e) => onChange(e)}
                        id="country"
                        required=""
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 ">
                    <div className="form-group  ">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        className="form-control bce5"
                        name="password"
                        value={password}
                        onChange={(e) => onChange(e)}
                        id="password"
                        required=""
                      ></input>
                    </div>
                  </div>
                  <div className="col-sm-6 ">
                    <div className="form-group  ">
                      <label htmlFor="confirmpassword">Confirm Password</label>
                      <input
                        type="password"
                        className="form-control bce5"
                        name="password2"
                        value={password2}
                        onChange={(e) => onChange(e)}
                        id="confirmpassword"
                        required=""
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={checked}
                    onChange={(e) => setChecked(!checked)}
                    id="flexCheckDefault"
                    style={{ marginLeft: "0px !importan", marginTop: "6px" }}
                  ></input>
                  <label
                    className="form-check-label px16"
                    htmlFor="flexCheckDefault"
                    style={{ marginLeft: "8px" }}
                  >
                    By clicking here, you agree with the terms <br />
                    and conditions of Cozillion
                  </label>
                </div>
                <input
                  type="submit"
                  className="btn btn-primary px20 fwb mt-5"
                  value="Sign Up"
                  style={{
                    width: "40%",
                    marginLeft: "29%",
                    backgroundColor: "#037BBE",
                    color: "#ffff",
                  }}
                ></input>
              </form>
              <div className="row justify-content-center mt-4 mb-5">
                <div className="col-10 col-sm-6">
                  <div className="row">
                    <div className="col-5 mt-2">
                      <div className="borderbottom"></div>
                    </div>
                    <div className="col-2 text-center px16 fwb">Or</div>
                    <div className="col-5 mt-2">
                      <div className="borderbottom"></div>
                    </div>
                  </div>
                  <button
                    type="btn "
                    className="btn  btn-size-p btn-google mt-4"
                  >
                    Continue with Google
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

SignUp.prototypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { setAlert, register })(SignUp);
