/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./cozillion.css";
import img1 from "../imgs/Component 26.png";
import img2 from "../imgs/img02.png";
import img3 from "../imgs/Image 173.png";

const Body = () => {
  return (
    <>
      <div className="container-fluid pt-5 pb-5">
        <div className="row justify-content-around">
          <div className="col-11 col-sm-5 two">
            <div className="cl-0d px18 hel mbp-5">
              {/* Using color to add meaning only provides a visual indication,
              which will not be conveyed to users of assistive technologies –
              such as screen readers. Ensure that information denoted by the
              color is either obvious from the content itself (e.g. the visible
              text), or is included through alternative means, such as
              additional text hidden with the */}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit
              amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
              ex ea commodo consequat.
              <div className="mt-3">
                <button type="button" className="btn btn-text">
                  Learn More
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-5 one">
            <div className="container ">
              <div className="row justify-content-center">
                <div className="col-12">
                  <img
                    className="rounded"
                    src={img1}
                    alt="part1-img"
                    style={{ width: "90%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bcd7 h-p2">
        <div className="row">
          <div className="col-12 col-sm-4 pt-5 pl-5">
            <img
              className="img-fluid rounded-circle"
              src={img2}
              style={{
                width: "270px  ",
                height: "270px",
                position: "relative",
                left: "5%",
              }}
            />
          </div>
          <div className="col-12 col-sm-8 pt-5">
            <div className="cl-0d px20 hel mt-40">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </div>
            <div className="cl-0d px20 hel fwb mt-25">Founder Name,Founder</div>
            <div className="cl-0d px20 hel">LaunchDarkly </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row mt-5 mb-5">
          <div className="col-12 col-sm-8 mt-5 mb-5">
            <div className="row ">
              <div className="col-4">
                <div className="box1">
                  <div className="b1"></div>
                  <div className="hb1"></div>
                </div>
              </div>
              <div className="col-4">
                <div className="box1">
                  <div className="b1"></div>
                  <div className="hb1"></div>
                </div>
              </div>
              <div className="col-4">
                <div className="box1">
                  <div className="b1"></div>
                  <div className="hb1"></div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-4">
                <div className="box1">
                  <div className="b1"></div>
                  <div className="hb1"></div>
                </div>
              </div>
              <div className="col-4">
                <div className="box1">
                  <div className="b1"></div>
                  <div className="hb1"></div>
                </div>
              </div>
              <div className="col-4">
                <div className="box1">
                  <div className="b1"></div>
                  <div className="hb1"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-4 mt-5 mb-5">
            <img
              className="img-fluid"
              src={img3}
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
