import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import "../cozillion.css";
import sellerlogin from "../../imgs/Image 89.png";
import { FaTwitterSquare, FaFacebookSquare, FaLinkedin } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { login } from "../../actions/auth";
import PropTypes from "prop-types";
import { setAlert } from "../../actions/alert";
import Alert from "../layout/Alert";
import { NavLink } from "react-router-dom";

/* Passing login here so that we do not need to write props.login every time*/
const Login = ({ setAlert, login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    user_type: "User",
  });

  const { email, password, user_type } = formData;

  const history = useHistory();

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    login(email, password, user_type);
  };

  //Redirect if authenticated
  if (isAuthenticated) {
    setTimeout(function () {
      history.push("/buy");
    }, 1000);
  }

  return (
    <Fragment>
      <div className="container-fluid mt-4 login-image">
        <div className="container ptb ">
          <div className="row justify-content-center">
            <div className="col-11 col-sm-7 sellerform pt-4 pb-4 hel">
              <div className="row justify-content-center">
                <div className="col-11 col-sm-8">
                  <div className="">
                    <div className="px42 text-center">Buyer's Login</div>
                    <Alert />
                    <form
                      onSubmit={(e) => onSubmit(e)}
                      method="post"
                      className="pt-3"
                    >
                      <div className="form-group">
                        <label htmlFor="email">Email address</label>
                        <input
                          type="email"
                          name="email"
                          value={email}
                          onChange={(e) => onChange(e)}
                          className="form-control bce5"
                          id="email"
                        ></input>
                      </div>
                      <div className="form-group">
                        <label htmlFor="pwd">Password</label>
                        <input
                          type="password"
                          name="password"
                          value={password}
                          onChange={(e) => onChange(e)}
                          className="form-control bce5"
                          id="pwd"
                        ></input>
                      </div>
                      <div style={{ position: "relative", height: "40px" }}>
                        <a
                          href="#"
                          className="text-info"
                          style={{ position: "absolute", right: "0px" }}
                        >
                          <NavLink
                            to="/forgot_password"
                            className="link px12"
                            activeclassname="active"
                          >
                            Forget Password?
                          </NavLink>
                        </a>
                      </div>

                      <button
                        type="submit"
                        className="btn bc03 btn-size-p px20 fwb cl-w"
                      >
                        Login
                      </button>
                    </form>
                  </div>
                  <div className="row mt-4">
                    <div className="col-5 mt-2">
                      <div className="borderbottom"></div>
                    </div>
                    <div className="col-2 text-center px16 fwb">Or</div>
                    <div className="col-5 mt-2">
                      <div className="borderbottom"></div>
                    </div>
                  </div>
                  <button
                    type="btn "
                    className="btn  btn-size-p btn-google mt-4"
                  >
                    Continue with Google
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { setAlert, login })(Login);
