import React from "react";
import HelloBuyer from "./HelloBuyer";

const Buyer = () => {
  return (
    <>
      <HelloBuyer />
    </>
  );
};

export default Buyer;
