import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./buyerSide.css";
import {
  getCartItems,
  addProductToCart,
  updateProductItem,
  addOrder,
} from "../../actions/cart";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Alert from "../layout/Alert";

const ShoppingBasket = ({
  getCartItems,
  addProductToCart,
  updateProductItem,
  addOrder,
  cart: { cart, cart_items },
}) => {
  const { id } = cart;
  const history = useHistory();

  useEffect(() => {
    getCartItems(id);
  }, []);

  const getPrice = (product) => {
    let p = 0;
    if (product.sale !== 0) {
      p = product.price * ((100 - product.sale) / 100);
      return p.toFixed(2);
    }
    return product.price;
  };

  const itemsList = Object.keys(cart_items).map((cart_item) => (
    <div key={cart_items[cart_item].id}>
      <div className="sb-img">
        <img src={cart_items[cart_item].product.image} width="120px" />
      </div>
      <div className="sb-title">
        Title: {cart_items[cart_item].product.title}
      </div>
      <div className="sb-seller">
        Seller: {cart_items[cart_item].product.user.name}
      </div>
      <div className="sb-price">
        Price : ${getPrice(cart_items[cart_item].product)}
      </div>
      <div className="sb-more">
        <span
          style={{
            cursor: "pointer",
            paddingRight: "20px",
            borderRight: "1px solid",
          }}
          onClick={() =>
            decrementItem(
              cart_items[cart_item].id,
              cart_items[cart_item].price,
              cart_items[cart_item].quantity,
              cart_items[cart_item].cart_id
            )
          }
        >
          -
        </span>
        <span
          style={{
            paddingRight: "20px",
            borderRight: "1px solid",
            paddingLeft: "20px",
            cursor: "pointer",
          }}
          onClick={() =>
            inrementItem(
              cart_items[cart_item].cart_id,
              cart_items[cart_item].product.id,
              cart_items[cart_item].product.price
            )
          }
        >
          +
        </span>
        <span
          style={{
            paddingRight: "20px",
            borderRight: "1px solid",
            paddingLeft: "20px",
          }}
        >
          Quantity: {cart_items[cart_item].quantity}
        </span>
      </div>
    </div>
  ));

  const inrementItem = (id, product_id, price) => {
    addProductToCart(id, product_id, price);
    getCartItems(id);
  };

  const decrementItem = (id, price, quantity, cart_id) => {
    console.log(id);
    var q = quantity - 1;
    if (quantity == 0) {
      return;
    }
    updateProductItem(id, price, q);
    getCartItems(cart_id);
  };

  const placeOrder = () => {
    addOrder(id, "PROCESSING_STOCK");
  };

  return (
    <Fragment>
      <div
        className="shoppingbasket"
        style={{ height: "700px", width: "100%", backgroundColor: "#f8f8f8" }}
      >
        {Object.keys(cart_items).length > 0 ? (
          <div
            className="shoppingbasket1"
            style={{ height: "240px", width: "100px" }}
          >
            <div
              className="sb sb-sb"
              style={{
                width: "70%",
                height: "240px",
                position: "absolute",
                marginLeft: "1%",
              }}
            >
              <div className="myaccount">
                <h3 style={{ marginLeft: "5%", color: "#365464" }}>
                  Shopping Basket
                </h3>
                <div
                  className="my-acc"
                  style={{
                    width: "86%",
                    borderBottom: "2px solid #365464",
                    marginLeft: "5%",
                  }}
                ></div>
              </div>
              <Alert />
              {itemsList}
            </div>
            <div
              className="sb sb-or"
              style={{
                width: "27%",
                height: "240px",
                position: "absolute",
                marginLeft: "72%",
              }}
            >
              <h4
                style={{
                  marginTop: "20px",
                  marginLeft: "5%",
                  color: "#365464",
                }}
              >
                Order Sumary
              </h4>
              <div style={{ marginLeft: "5%" }}>
                Subtotal{" "}
                <span style={{ right: "10%", position: "absolute" }}>
                  ${cart.totalPrice}
                </span>
              </div>
              <div style={{ marginLeft: "5%" }}>
                Shiping{" "}
                <span style={{ right: "10%", position: "absolute" }}>$5</span>
              </div>
              <div
                className="my-acc"
                style={{
                  width: "86%",
                  borderBottom: "1px solid #365464",
                  marginLeft: "5%",
                }}
              ></div>
              <div style={{ marginLeft: "5%" }}>
                Total{" "}
                <span style={{ right: "10%", position: "absolute" }}>
                  ${cart.totalPrice + 5}
                </span>
              </div>
              <div
                style={{ cursor: "pointer" }}
                className="sb-btn"
                onClick={() => placeOrder()}
              >
                BUY{" "}
              </div>

              {/* <button role="link" onClick={handleClick}>
              Checkout
            </button> */}
            </div>
          </div>
        ) : (
          <h3 style={{ textAlign: "center" }}>Shopping Basket Is Empty!</h3>
        )}
        <div
          className="shoppingbasket2"
          style={{ marginTop: "40px", height: "240px", width: "100px" }}
        >
          {/* <div
            className="sb sb-it"
            style={{
              width: "70%",
              height: "240px",
              position: "absolute",
              marginLeft: "1%",
            }}
          >
            <div className="myaccount">
              <h3 style={{ marginLeft: "5%", color: "#365464" }}>Your Items</h3>
              <div
                className="my-acc"
                style={{
                  width: "86%",
                  borderBottom: "2px solid #365464",
                  marginLeft: "5%",
                }}
              ></div>
            </div>

            <div className="sb-img">img</div>
            <div className="sb-title">Habitat Rufus Valvet</div>
            <div className="sb-seller">Seller:Seller Name</div>
            <div className="sb-price">
              $15.99 <span>In Stock</span>
            </div>
            <div className="sb-more">
              <span style={{ paddingRight: "20px", borderRight: "1px solid" }}>
                Delete
              </span>
              <span
                style={{
                  paddingRight: "20px",
                  borderRight: "1px solid",
                  paddingLeft: "20px",
                }}
              >
                Add to Basket
              </span>
              <span
                style={{
                  paddingRight: "20px",
                  borderRight: "1px solid",
                  paddingLeft: "20px",
                }}
              >
                More like this
              </span>
            </div>
          </div> */}
          {/* <div
            className="sb sb-bu"
            style={{
              width: "27%",
              height: "240px",
              position: "absolute",
              marginLeft: "72%",
            }}
          >
            <div className="myaccount">
              <h4
                style={{
                  marginLeft: "5%",
                  color: "#274758",
                  paddingTop: "20px",
                }}
              >
                Buy it Again
              </h4>
              <div
                className="my-acc"
                style={{
                  width: "86%",
                  borderBottom: "1px solid #0f3346",
                  marginLeft: "5%",
                }}
              ></div>
            </div>

            <div className="ag-img">img</div>
            <div className="ag-title">Habitat Rufus Valvet</div>
            <div className="ag-star">*****</div>
            <div className="ag-price">$15.99</div>
            <div className="ag-again">Purchased on 13rd March</div>
            <div className="ag-open">Buy Again</div>
          </div> */}
        </div>

        {/* <div className="shoppingbasket3">
          <div
            style={{ marginLeft: "25px", marginTop: "20px", fontSize: "1.2em" }}
          >
            Your Browsing History
          </div>
          <div
            className="shopingSlider"
            style={{
              border: "1px solid",
              width: "96%",
              height: "120px",
              marginLeft: "2%",
            }}
          ></div>
        </div> */}
      </div>
    </Fragment>
  );
};

ShoppingBasket.prototypes = {
  getCartItems: PropTypes.func.isRequired,
  addProductToCart: PropTypes.func.isRequired,
  updateProductItem: PropTypes.func.isRequired,
  addOrder: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  cart: state.cart,
});

export default connect(mapStateToProps, {
  getCartItems,
  addProductToCart,
  updateProductItem,
  addOrder,
})(ShoppingBasket);
