import {
  GET_CART,
  CART_ERROR,
  CREATE_CART_SUCCESS,
  LOGOUT,
  GET_CART_ITEMS,
  EMPTY_CART,
  GET_SHOP_ORDERS,
  GET_USER_ORDERS,
} from "../actions/types";

const initialState = {
  cart: {},
  cart_items: {},
  shop_orders: {},
  user_orders: {},
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CART:
    case CREATE_CART_SUCCESS:
      return {
        ...state,
        cart: payload,
        loading: false,
      };

    case GET_CART_ITEMS:
      return {
        ...state,
        cart_items: payload,
        loading: false,
      };

    case GET_SHOP_ORDERS:
      return {
        ...state,
        shop_orders: payload,
        loading: false,
      };

    case GET_USER_ORDERS:
      return {
        ...state,
        user_orders: payload,
        loading: false,
      };

    case CART_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case LOGOUT:
    case EMPTY_CART:
      return {
        ...state,
        cart: {},
        cart_items: {},
        shop_orders: {},
        loading: false,
      };
    default:
      return state;
  }
}
