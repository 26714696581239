import React from "react";
import "./buyerSide.css";
import { Link } from "react-router-dom";
import img1 from "../../imgs/product1.png";
import img2 from "../../imgs/product2.png";
import img3 from "../../imgs/product3.png";
import img4 from "../../imgs/product4.png";
import img5 from "../../imgs/product5.png";
import img6 from "../../imgs/product6.png";
import img7 from "../../imgs/product7.png";
import img8 from "../../imgs/product8.png";
import img9 from "../../imgs/product9.png";
import img10 from "../../imgs/product10.png";
import mes from "../../imgs/Component 35.png";
import HelpHeader from "./Help-Header";

const HomePageBuyer = () => {
  return (
    <>
      <HelpHeader />
      <div className="container-fluid bc-f8 hel">
        <div className="row justify-content-around pt-4">
          <div className="col-5 findout">
            <div className="row justify-content-center pt-4">
              <div className="col-10 text">
                <div className="px28 hel">
                  New Suppliers for cleaning products!
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-3 mb-2">
              <div className="col-4 text-center pb-4">
                <button className="btn btn-find">
                  <Link
                    to="/Buy/Products"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Learn more
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="container b-w mt-5">
          <div className="row bc-white">
            <div className="col-4">
              <div className="row ">
                <div className="col-10 ">
                  <h4 className="bb-col">Products</h4>
                </div>
              </div>
              <div className="row">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>Img</th>
                      <th>Detail</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>John</td>
                      <td>Doe</td>
                    </tr>
                    <tr>
                      <td>Mary</td>
                      <td>Moe</td>
                    </tr>
                    <tr>
                      <td>July</td>
                      <td>Dooley</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-8"></div>
          </div>
        </div>

        <div className="container mt-3 pb-4">
          <div className="row">
            <div className="col-4 bb-r"></div>
            <div className="col-4 text-center">
              <div className="px30 fwb cl-e2">Latest Products</div>
            </div>
            <div className="col-4 bb-r"></div>
          </div>
        </div>
      </div>
      <div className="container-fluid bc-f8 pb-4">
        <div className="row pb-2">
          <div className="col-3">
            <img src={img1} style={{ height: "100%", width: "100%" }} />
          </div>
          <div className="col-3">
            <img src={img2} style={{ height: "100%", width: "100%" }} />
          </div>
          <div className="col-3">
            <img src={img3} style={{ height: "100%", width: "100%" }} />
          </div>
          <div className="col-3">
            <img src={img4} style={{ height: "100%", width: "100%" }} />
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <img src={img5} style={{ height: "100%", width: "100%" }} />
          </div>
          <div className="col-3">
            <img src={img6} style={{ height: "100%", width: "100%" }} />
          </div>
          <div className="col-3">
            <img src={img7} style={{ height: "100%", width: "100%" }} />
          </div>
          <div className="col-3">
            <img src={img8} style={{ height: "100%", width: "100%" }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePageBuyer;
