import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import "../cozillion.css";
import sellerlogin from "../../imgs/Image 89.png";
import { FaTwitterSquare, FaFacebookSquare, FaLinkedin } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { forgotPassword } from "../../actions/auth";
import PropTypes from "prop-types";
import { setAlert } from "../../actions/alert";
import Alert from "../layout/Alert";
import { Redirect } from "react-router-dom";

/* Passing login here so that we do not need to write props.login every time*/
const ForgotPassword = ({ setAlert, forgotPassword }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    user_type: "User",
  });

  const { email } = formData;

  const history = useHistory();

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    forgotPassword(email);
  };

  return (
    <Fragment>
      <div
        className="container-fluid mt-4"
        style={{
          background: `url("${sellerlogin}")`,
          height: "100%",
          width: "100%",
        }}
      >
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-5 sellerform pt-4 pb-4">
              <div className="text-center">
                <h2>Forgot Password</h2>
              </div>

              <Alert />
              <form
                onSubmit={(e) => onSubmit(e)}
                method="post"
                className="pt-3"
              >
                <div className="form-group">
                  <label htmlFor="email">Email address:</label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                    placeholder="Enter email"
                    id="email"
                  ></input>
                </div>

                <button type="submit" className="btn btn-primary btn-size-p">
                  Submit
                </button>
                <div className="form-group"></div>
              </form>
              {/* <div className="row justify-content-center">
                <div className="col-6 text-center">
                  <div>
                    <h3>Or</h3>
                  </div>
                  <div>Sign in with:</div>
                  <div style={{ fontSize: "1.5em" }}>
                    <FaFacebookSquare style={{ color: "#1A5276" }} />
                    <FaTwitterSquare
                      style={{ color: "#85C1E9", marginLeft: "3px" }}
                    />
                    <FcGoogle style={{ marginLeft: "3px" }} />
                    <FaLinkedin
                      style={{ color: "#2874A6", marginLeft: "3px" }}
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { setAlert, forgotPassword })(
  ForgotPassword
);
