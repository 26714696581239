import React from "react";
import "./buyerSide.css";
import HelpHeader from "./Help-Header";

const ViewSuppliersPage = () => {
  return (
    <>
      <HelpHeader />
      <div className="supplierpage" style={{ height: "660px" }}>
        <div className="myaccount">
          <h3 style={{ marginLeft: "3%", color: "#365464" }}>Suppliers</h3>
          <div
            className="my-acc"
            style={{
              width: "94%",
              borderBottom: "2px solid #365464",
              marginLeft: "3%",
            }}
          ></div>
        </div>
        <div style={{ height: "510px" }}>
          <div className="sup-category sup">
            <h4 style={{ marginLeft: "3%", color: "#365464" }}>Categories</h4>
            <div
              className="my-acc"
              style={{
                width: "94%",
                borderBottom: "2px solid #365464",
                marginLeft: "3%",
              }}
            ></div>
            <div style={{ marginLeft: "10%", marginTop: "5%" }}>
              <tr>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                ></input>
                <label className="form-check-label" htmlFor="defaultCheck1">
                  Product Type
                </label>
              </tr>
              <tr>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                ></input>
                <label className="form-check-label" htmlFor="defaultCheck1">
                  Product Type
                </label>
              </tr>
              <tr>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                ></input>
                <label className="form-check-label" htmlFor="defaultCheck1">
                  Product Type
                </label>
              </tr>
              <tr>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                ></input>
                <label className="form-check-label" htmlFor="defaultCheck1">
                  Product Type
                </label>
              </tr>
              <tr>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                ></input>
                <label className="form-check-label" htmlFor="defaultCheck1">
                  Product Type
                </label>
              </tr>
              <tr>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                ></input>
                <label className="form-check-label" htmlFor="defaultCheck1">
                  Product Type
                </label>
              </tr>
            </div>
          </div>
          <div className="sup-imgs sup">
            <div className="img-row">
              <div className="row-img"></div>
              <div className="row-img"></div>
              <div className="row-img"></div>
              <div className="row-img"></div>
            </div>
            <div className="img-row">
              <div className="row-img"></div>
              <div className="row-img"></div>
              <div className="row-img"></div>
              <div className="row-img"></div>
            </div>
            <div className="img-row">
              <div className="row-img"></div>
              <div className="row-img"></div>
              <div className="row-img"></div>
              <div className="row-img"></div>
            </div>
            <div className="img-row">
              <div className="row-img"></div>
              <div className="row-img"></div>
              <div className="row-img"></div>
              <div className="row-img"></div>
            </div>
          </div>
        </div>
        <div className="sup-pages">
          <div
            className="sup-no"
            style={{
              marginTop: "7px",
              position: "absolute",
              marginLeft: "22%",
            }}
          >
            Showig 1 to 10 of 57 entries
          </div>
          <div
            className="sup-btn"
            style={{
              marginTop: "-25",
              position: "absolute",
              right: "3%",
              marginTop: "6px",
            }}
          >
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link" href="#">
                    Previous
                  </a>
                </li>
                {/* <li className="page-item"><a className="page-link" href="#">1</a></li>
                             <li className="page-item"><a className="page-link" href="#">2</a></li>
                             <li className="page-item"><a className="page-link" href="#">3</a></li> */}
                <li className="page-item">
                  <a className="page-link" href="#">
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewSuppliersPage;
