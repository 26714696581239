import "./App.css";
import { Fragment, useEffect } from "react";
import logo from "./imgs/Group 449.png";
import {
  BrowserRouter as Router,
  HashRouter,
  NavLink,
  Switch,
  Route,
} from "react-router-dom";
import Routes from "./Components/routing/Routes";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";

import { Provider } from "react-redux";
import store from "./store";
import Footer from "./Components/Footer";
import Header from "./Components/Header";

import { createBrowserHistory } from "history";
const history = createBrowserHistory();

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <HashRouter history={history} basename={"/cozillion"}>
        <Fragment>
          <Route component={Routes} />
        </Fragment>
      </HashRouter>
    </Provider>
  );
}

export default App;
