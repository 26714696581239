import {
  GET_PROFILE,
  PROFILE_ERROR,
  GET_PRODUCTS,
  GET_PRODUCT,
  PRODUCTS_ERROR,
  UPDATE_PRODUCTS,
  UPDATE_PRODUCT,
  SET_QUERY,
} from "../actions/types";

const initialState = {
  profile: null,
  products: [],
  product: null,
  loading: true,
  error: {},
  query: "",
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PRODUCTS:
    case UPDATE_PRODUCTS:
      return {
        ...state,
        products: payload,
        loading: false,
        product: null,
      };

    case GET_PRODUCT:
    case UPDATE_PRODUCT:
      return {
        ...state,
        product: payload,
        loading: false,
      };

    case SET_QUERY:
      return {
        ...state,
        query: payload,
        loading: false,
      };
    case PRODUCTS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
