import React, { Fragment, useState, useEffect } from "react";
import "./buyerSide.css";
import img7 from "../../imgs/product7.png";
import HelpHeader from "./Help-Header";
import { getAllProducts } from "../../actions/profile";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Alert from "../layout/Alert";
import { createCart, addProductToCart } from "../../actions/cart";
import { NavLink } from "react-router-dom";

const NewInPage = ({
  getAllProducts,
  cart: { cart },
  profile: { products },
  createCart,
  addProductToCart,
}) => {
  useEffect(() => {
    getAllProducts();
  }, [getAllProducts]);
  const { id } = cart;
  const addProduct = (product_id, price) => {
    if (id != undefined) {
      addProductToCart(id, product_id, price);
    } else {
      createCart(product_id, price);
    }
  };
  const getPrice = (product) => {
    let p = 0;
    if (product.sale !== 0) {
      p = product.price * ((100 - product.sale) / 100);
      return p.toFixed(2);
    }
    return product.price;
  };
  const productsList = products.map((product) => (
    <div key={product.id} className="fp-it">
      <div className="fp-img">
        <NavLink
          to={"Product/" + product.id}
          exact
          className="link"
          activeclassname="active"
        >
          <img src={product.image} style={{ height: "100%", width: "100%" }} />
        </NavLink>
      </div>
      <div className="fp-name">{product.title}</div>
      <div className="fp-star">*****</div>
      <div className="fp-price">${getPrice(product)}</div>
      {product.stock == 0 ? (
        <div style={{ cursor: "pointer" }} className="fp-buy">
          Out Of Stock
        </div>
      ) : (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => addProduct(product.id, product.price)}
          className="fp-buy"
        >
          BUY
        </div>
      )}
    </div>
  ));

  return (
    <>
      <HelpHeader />
      <div
        className="newinpage pb-5 hel"
        style={{ backgroundColor: "#f8f8f8", paddingTop: "20px" }}
      >
        <div className="myaccount">
          <h2 style={{ marginLeft: "5%", color: "#274758" }}>New In</h2>
          <div
            className="my-acc"
            style={{
              width: "86%",
              borderBottom: "2px solid #0f3346",
              marginLeft: "5%",
            }}
          ></div>
        </div>
        <Alert />
        <div className="newitems">{productsList}</div>
      </div>
    </>
  );
};
NewInPage.prototypes = {
  getAllProducts: PropTypes.func.isRequired,
  createCart: PropTypes.func.isRequired,
  addProductToCart: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  cart: state.cart,
  profile: state.profile,
});

export default connect(mapStateToProps, {
  getAllProducts,
  createCart,
  addProductToCart,
})(NewInPage);
