import React from "react";
import "./seller.css";
import { MdNotificationsNone } from "react-icons/md";
import { FiMail } from "react-icons/fi";
import img from "../../imgs/Group 456.png";
import img4 from "../../imgs/Image 85.png";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

const SellerHeader = ({ auth: { user } }) => {
  return (
    <>
      <div className="row bck desktop">
        <div className="col-6">
          {/* <input type="text" style={{ width: "75%" }} className="mt-3"></input>
          <span className="header-search">Search</span> */}
        </div>
        <div className="col-2">
          <div className="row justify-content-center cl-w mt-1">
            <div className="col-4 f-z3">
              <MdNotificationsNone />
            </div>
            <div className="col-4 f-z3">
              <FiMail />
            </div>
          </div>
        </div>
        <div className="col-4 ">
          <div className=" ml-1">
            <img
              src={user != null ? user.user.image : ""}
              style={{ width: "10%" }}
            />
            <div style={{ color: "white" }} className="col-5">
              {user != null ? user.user.name : ""}{" "}
              {user != null ? user.user.last_name : ""}
              <a>
                <NavLink
                  to="/seller/profile"
                  exact
                  className="link cl-w"
                  activeclassname="active"
                >
                  My Account
                </NavLink>
              </a>
            </div>
          </div>
        </div>
        {/* <div className="col-1 mt-3">
          <button
            type="button"
            className="btn  dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ color: "white" }}
          >
            USD
          </button>
        </div> */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(SellerHeader);
