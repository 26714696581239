import React, { useEffect, Fragment } from "react";
import { Link, Redirect, NavLink } from "react-router-dom";
import "./buyerSide.css";
import visa from "../../imgs/visa13.png";
import { deleteAddress, getUserAddresses } from "../../actions/profile";
import Spinner from "../layout/Spinner";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import HelpHeader from "./Help-Header";

const WalletPage = ({
  getUserAddresses,
  deleteAddress,
  addresses: { address, addresses, loading },
}) => {
  useEffect(() => {
    getUserAddresses();
  }, [getUserAddresses]);
  let count = 1;
  const addressesList = addresses.map((address) => (
    <tr key={address.id}>
      <td>{count++}</td>
      <td>{address.address}</td>
      <td>
        <button type="button" class="btn">
          <Link to={"address/edit/" + address.id}>Edit Address Details</Link>
        </button>
        <button
          onClick={() => deleteAddress(address.id)}
          type="button"
          class="btn btn-danger"
        >
          Delete
        </button>
      </td>
    </tr>

    // <div key={address.id} className="visawallet">
    //   <div className="visa-detail">
    //     <div>Address: {address.address}</div>
    //   </div>
    //   <div className="visa-btn">
    //     <Link className="btn-address" to={"address/edit/" + address.id}>
    //       Edit Address Details
    //     </Link>
    //     <br />
    //     <div className="btn-address" onClick={() => deleteAddress(address.id)}>
    //       Delete
    //     </div>
    //   </div>
    // </div>
  ));

  return loading && address === null ? (
    <Spinner />
  ) : (
    <>
      <HelpHeader />
      <div className="walletpage">
        <div className="myaccount">
          <h2
            style={{ marginLeft: "5%", color: "#274758", paddingTop: "20px" }}
          >
            Addresses
          </h2>
          <div
            className="my-acc"
            style={{
              width: "90%",
              borderBottom: "2px solid #0f3346",
              marginLeft: "5%",
            }}
          ></div>
        </div>
        <div style={{ margin: "100px" }}>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{addressesList}</tbody>
          </table>
        </div>

        <div className="row justify-content-center">
          <div className="col-10">
            <div className="addproducts">
              <NavLink
                to="/Buy/Address/Add"
                exact
                className="link"
                activeclassname="active"
              >
                {" "}
                Add Address{" "}
              </NavLink>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

WalletPage.prototypes = {
  getUserAddresses: PropTypes.func.isRequired,
  address: PropTypes.object.isRequired,
  deleteAddress: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  addresses: state.addresses,
});

export default connect(mapStateToProps, { getUserAddresses, deleteAddress })(
  WalletPage
);
