import React, { Fragment, useState, useEffect } from "react";
import img from "../../imgs/Image 153.png";
import { connect } from "react-redux";
import { getProductCategories } from "../../actions/category";
import auth from "../../reducers/auth";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";
import { createCard } from "../../actions/profile";
import { setAlert } from "../../actions/alert";
import setAuthToken from "../../utils/setAuthToken";
import Alert from "../layout/Alert";
import HelpHeader from "./Help-Header";

const AddCard = ({ setAlert, createCard }) => {
  const [formData, setFormData] = useState({
    bank_name: "",
    title: "",
    number: "",
    expiry_date: "",
    security_code: "",
  });

  const { bank_name, title, number, expiry_date, security_code } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    createCard({
      bank_name,
      title,
      number,
      expiry_date,
      security_code,
    });
  };
  return (
    <Fragment>
      <HelpHeader />
      <div className="row bc-b9 pb-3 pt-3">
        <form
          method="post"
          onSubmit={(e) => onSubmit(e)}
          encType="multipart/form-data"
        >
          <div className="container">
            <Alert />
            <div>
              <h3>Add Card</h3>
            </div>
            <div className="form-group">
              <label htmlFor="usr">Bank Name:</label>
              <input
                type="text"
                name="bank_name"
                value={bank_name}
                onChange={(e) => onChange(e)}
                className="form-control"
                id="usr"
              />
            </div>
            <div className="form-group">
              <label htmlFor="usr">Title:</label>
              <input
                type="text"
                name="title"
                value={title}
                onChange={(e) => onChange(e)}
                className="form-control"
                id="usr"
              />
            </div>
            <div className="form-group">
              <label htmlFor="pwd">Number:</label>
              <input
                type="text"
                name="number"
                value={number}
                onChange={(e) => onChange(e)}
                className="form-control"
                id="pwd"
              />
            </div>
            <div className="form-group">
              <label htmlFor="pwd">Expiry Date:</label>
              <input
                type="text"
                name="expiry_date"
                value={expiry_date}
                onChange={(e) => onChange(e)}
                className="form-control"
                id="pwd"
              />
            </div>

            <div className="form-group">
              <label htmlFor="pwd">Security Code:</label>
              <input
                type="text"
                name="security_code"
                value={security_code}
                onChange={(e) => onChange(e)}
                className="form-control"
                id="pwd"
              />
            </div>

            <br />
            <br />
            <div className="row justify-content-center">
              <div className="col-10">
                <input
                  type="submit"
                  className="btn btn-primary"
                  value="Insert"
                  style={{
                    width: "40%",
                    marginLeft: "29%",
                    backgroundColor: "#5DADE2",
                  }}
                ></input>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

AddCard.prototypes = {
  setAlert: PropTypes.func.isRequired,
  createCard: PropTypes.func.isRequired,
};

export default connect(null, {
  setAlert,
  createCard,
})(AddCard);
