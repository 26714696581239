import React from "react";
import SellerSignup from "./SellerSignUp";

const SellerSignupPage = () => {
  return (
    <>
      <SellerSignup />
    </>
  );
};

export default SellerSignupPage;
