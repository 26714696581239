import React from "react";
import "./cozillion.css";
import { Link } from "react-router-dom";
import img1 from "../imgs/Group 449.png";
import img2 from "../imgs/Component 32.png";
import img3 from "../imgs/Rectangle 57.png";
import img4 from "../imgs/Image 140.png";
import img5 from "../imgs/Image 141.png";

const Footer = () => {
  return (
    <>
      <div className="container-fluid bck pt-5 pb-5">
        <div className="row hel">
          <div className="col-10 col-sm-3 ">
            <div className="col">
              <img className="img-fluid" src={img1} width="70%" />
            </div>

            <div className="col mt-4">
              <img className="img-fluid" src={img2} width="60%" />
            </div>
          </div>
          <div className="col-12 col-sm-8">
            <div className="row cl-w">
              <div className="col-6 col-sm-3 ">
                <div className="quick px18">QUICK LINKS</div>
                <div className="red-line">
                  <img src={img3} style={{ height: "100%", width: "100%" }} />
                </div>
                <div className="home px16 mt-4">
                  <Link
                    to="/"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Home
                  </Link>
                </div>
                <div className="about px16 mt-2">
                  <Link
                    to="/about"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    About
                  </Link>
                </div>
                <div className="buyer-f px16 mt-2">
                  <Link
                    to="/buyer"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Buyer
                  </Link>
                </div>
                <div className="seller px16 mt-2">
                  <Link
                    to="/seller"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Seller
                  </Link>
                </div>
                <div className="contact px16 mt-2">
                  <Link
                    to="/contact"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Contact
                  </Link>
                </div>
              </div>
              <div className="col-6 col-sm-3">
                <div className="extra px18 ">EXTRA</div>
                <div className="red-line">
                  <img src={img3} style={{ height: "100%", width: "100%" }} />
                </div>
                <div className="privacy px16 mt-4">Privacy Policy</div>
                <div className="term px16 mt-2">Terms and Condition</div>
                <div className="fq px16 mt-2">FAQ's</div>
              </div>
              <div className="col-8 col-sm-3">
                <div className="get-in px18">GET IN TOUCH</div>
                <div className="red-line">
                  <img src={img3} style={{ height: "100%", width: "100%" }} />
                </div>
                <div className="company px16 mt-4">
                  <img src={img4} />
                  Company Email
                </div>
                <div className="phone px16 mt-2" style={{ marginTop: "30px" }}>
                  <img src={img5} />
                  Company Phone Number
                </div>
              </div>
              <div className="col-8 col-sm-3 ">
                <div className="px18">Subscribe to our Newsletter</div>
                <div
                  className="input-group mb-3 mt-2"
                  style={{ width: "130%" }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="example@gmail.com"
                    aria-describedby="basic-addon2"
                    style={{ borderRadius: "9px 0px 0px 9px" }}
                  ></input>
                  <div
                    className="input-group-append"
                    style={{ borderRadius: "0px 9px 9px 0px" }}
                  >
                    <button
                      className="input-group-text cl-w"
                      style={{ backgroundColor: "#037BBE" }}
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
