import React from "react";
import "./cozillion.css";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import img1 from "../imgs/slider1.png";
import img2 from "../imgs/slider2.png";
import img3 from "../imgs/slider3.png";

const Slider = () => {
  return (
    <>
      <div className="slider">
        <div className="slide-container">
          <Slide>
            <div
              className="container-fluid slider-images"
              style={{
                backgroundImage: `linear-gradient(
                    rgba(29, 27, 27, 0.45),
                    rgba(35, 34, 34, 0.45)
                  ),url("${img1}")`,
                //     height: "660px",
                width: "100%",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <div className="row justify-content-center">
                <div className="col-12 col-sm-6 text-center">
                  <div className="slide-header">BUY IN BULK NOW</div>
                  <div className="slide-subheading">
                    The best wholesale app available
                  </div>

                  <button type="button" className="btn btn-slide mt-3">
                    LEARN MORE
                  </button>
                </div>
              </div>
            </div>
            {/* slider2 */}
            <div
              className="container-fluid slider-images"
              style={{
                backgroundImage: `linear-gradient(
                    rgba(29, 27, 27, 0.45),
                    rgba(35, 34, 34, 0.45)
                  ),url("${img2}")`,
                //   height: "660px",
                width: "100%",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <div className="row justify-content-center">
                <div className="col-12 col-sm-6 text-center">
                  <div className="slide-header">BUY IN BULK NOW</div>
                  <div className="slide-subheading">
                    The best wholesale app available
                  </div>

                  <button type="button" className="btn btn-slide mt-3">
                    LEARN MORE
                  </button>
                </div>
              </div>
            </div>
            {/* slider 3 */}
            <div
              className="container-fluid slider-images"
              style={{
                backgroundImage: `linear-gradient(
                    rgba(29, 27, 27, 0.45),
                    rgba(35, 34, 34, 0.45)
                  ),url("${img3}")`,
                //  height: "660px",
                width: "100%",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <div className="row justify-content-center">
                <div className="col-12 col-sm-6 text-center">
                  <div className="slide-header">BUY IN BULK NOW</div>
                  <div className="slide-subheading">
                    The best wholesale app available
                  </div>

                  <button type="button" className="btn btn-slide mt-3">
                    LEARN MORE
                  </button>
                </div>
              </div>
            </div>
          </Slide>
        </div>
      </div>
    </>
  );
};

export default Slider;
