import React from "react";
import "./seller.css";

const AccountPage = () => {
  return (
    <>
      <div className="accountpage">
        <div className="myaccountbuyer" style={{ backgroundColor: "white" }}>
          <div className="myaccount">
            <h2
              style={{ marginTop: "20px", marginLeft: "5%", color: "#0f3346" }}
            >
              My Account
            </h2>
            <div
              className="my-acc"
              style={{
                width: "86%",
                borderBottom: "2px solid",
                marginLeft: "5%",
                color: "#0f3346",
              }}
            ></div>
          </div>
          <div className="myaccount">
            <h4
              style={{ marginTop: "30px", marginLeft: "5%", color: "#0f3346" }}
            >
              Personal Information
            </h4>
            <div
              className="my-acc"
              style={{
                width: "86%",
                borderBottom: "2px solid",
                marginLeft: "5%",
                color: "#0f3346",
              }}
            ></div>
          </div>

          <form
            action="/examples/actions/confirmation.php"
            method="post"
            style={{ marginTop: "15px" }}
          >
            <div className="row">
              <div className="col-sm-6 ">
                <div className="form-group fr-co by-co">
                  <label htmlFor="inputFirstName">First Name</label>
                  <input
                    type="text"
                    className="form-control bc-cl"
                    id="inputFirstName"
                    required=""
                  ></input>
                </div>
              </div>
              <div className="col-sm-6 ">
                <div className="form-group fr-co by-oo">
                  <label htmlFor="inputLastName">Last Name</label>
                  <input
                    type="text"
                    className="form-control bc-cl"
                    id="inputLastName"
                    required=""
                  ></input>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 ">
                <div className="form-group fr-co by-co">
                  <label htmlFor="phoneName">Phone Number</label>
                  <input
                    type="number"
                    className="form-control bc-cl"
                    id="phoneName"
                    required=""
                  ></input>
                </div>
              </div>
              <div className="col-sm-6 ">
                <div className="form-group fr-co by-oo">
                  <label htmlFor="companyName">Company Name</label>
                  <input
                    type="text"
                    className="form-control bc-cl"
                    id="companyName"
                    required=""
                  ></input>
                </div>
              </div>
            </div>

            <input
              type="submit"
              className="btn btn-primary"
              value="Submit"
              style={{
                position: "absolute",
                width: "15%",
                right: "13%",
                backgroundColor: "#38b6f9",
                borderBlockColor: "#38b6f9",
              }}
            ></input>
          </form>

          <div className="myaccount">
            <h4
              style={{ marginTop: "40px", marginLeft: "5%", color: "#0f3346" }}
            >
              Email Address
            </h4>
            <div
              className="my-acc"
              style={{
                width: "86%",
                borderBottom: "2px solid",
                marginLeft: "5%",
                color: "#0f3346",
              }}
            ></div>
          </div>

          <form
            action="/examples/actions/confirmation.php"
            method="post"
            style={{ marginTop: "15px" }}
          >
            <div className="row">
              <div className="col-sm-12 ">
                <div className="form-group by-co">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="form-control cn-e"
                    id="email"
                    required=""
                  ></input>
                </div>
              </div>
            </div>

            <input
              type="submit"
              className="btn btn-primary"
              value="Submit"
              style={{
                position: "absolute",
                width: "15%",
                right: "13%",
                backgroundColor: "#38b6f9",
                borderBlockColor: "#38b6f9",
              }}
            ></input>
          </form>

          <div className="myaccount">
            <h4
              style={{ marginTop: "40px", marginLeft: "5%", color: "#0f3346" }}
            >
              Password
            </h4>
            <div
              className="my-acc"
              style={{
                width: "86%",
                borderBottom: "2px solid",
                marginLeft: "5%",
                color: "#0f3346",
              }}
            ></div>
          </div>

          <form
            action="/examples/actions/confirmation.php"
            method="post"
            style={{ marginTop: "15px" }}
          >
            <div className="row">
              <div className="col-sm-6 ">
                <div className="form-group fr-co by-co">
                  <label htmlFor="currentPassword">Current Password</label>
                  <input
                    type="password"
                    className="form-control bc-cl"
                    id="currentPassword"
                    required=""
                  ></input>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 ">
                <div className="form-group fr-co by-co">
                  <label htmlFor="newPassword">New Password</label>
                  <input
                    type="password"
                    className="form-control bc-cl"
                    id="newPassword"
                    required=""
                  ></input>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 ">
                <div className="form-group fr-co by-co">
                  <label htmlFor="cnPassword">Confirm New Password</label>
                  <input
                    type="password"
                    className="form-control bc-cl"
                    id="cnPassword"
                    required=""
                  ></input>
                </div>
              </div>
            </div>

            <input
              type="submit"
              className="btn btn-primary"
              value="Submit"
              style={{
                width: "15%",
                marginLeft: "60px",
                backgroundColor: "#38b6f9",
                borderBlockColor: "#38b6f9",
              }}
            ></input>
          </form>
        </div>
      </div>
    </>
  );
};

export default AccountPage;
